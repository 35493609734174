/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

import { useTranslation } from 'react-i18next';

// reactstrap components
import { Row, Container } from 'reactstrap';

function Footer() {
  const { t } = useTranslation();
  const showLicense = window.location.pathname.indexOf('index') >= 0;
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            {showLicense && (
              <ul className="license">
                <li>
                  Associated marriage and family therapist <a>pre-license # 132009</a>
                </li>
                <li>
                  Supervised by Angela Porter <a>license # 99935</a>
                </li>
              </ul>
            )}
            <ul>
              <li>
                {t('feeAndContact.form.email')}:
                <a href="mailto: nizhang.psytherapy@gmail.com" target="_blank">
                  nizhang.psytherapy@gmail.com
                </a>
              </li>
              <li>
                {t('feeAndContact.form.address')}:
                <a href="https://goo.gl/maps/GnizrZsooihBeADUA" target="_blank">
                  444 34th st. Oakland, 94608
                </a>
              </li>
              <li>
                {t('feeAndContact.form.phone')}:
                <a href="sms://5105191565" target="_blank">
                  (510) 519-1565
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">© {new Date().getFullYear()}, Ni Zhang Therapy</span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
