/* eslint-disable jsx-a11y/alt-text */
import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.jsx';
import Footer from 'components/Footers/Footer.jsx';
import YoutubeModal from 'components/YoutubeModal.jsx';

function XinLiYuePage() {
  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="page-header" style={{ minHeight: '120px', backgroundImage: 'url(' + require('assets/img/antoine-barres.jpg') + ')' }} data-parallax={true}></div>
      <div className="">
        <Container>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', margin: '16px 0' }}>
            <YoutubeModal videoId="PmuthJCpclM" videoTitle="什么是舞动治疗 -- 身体表达的内容，被遗失了么？" />
            <YoutubeModal videoId="583zousJrvY" videoTitle="心理治疗师趣谈｜人人都有创造力，你的被浪费了么？" />
            <YoutubeModal videoId="BVytd6ggxO8" videoTitle="心理治疗师趣谈｜你的身体一直渴望与你沟通，你听到了么" />
          </div>
          <div
            className="row tn-article-body tn-opera-house display-system-page-margins"
            tn-opera-house="{ gesture: false }"
            tn-atom-context="{ type: 'paper', inReader: true, animationEnabled: false }"
            ng-class="{ 'display-system-page-margins': !!displaySystemPageMargins }">
            <article
              className="tn-cube-box paper dock-loader atom-data-binding ng-scope tn-from-house-reader_paper-cp tn-comp-anim-pin tn-comp-inst tn-cube-box-inst tn-comp tn-in-cell-state-active"
              ng-show="!htmlForPreview"
              tn-bind-comp-tpl-id="reader_paper-cp:sys/loader-cubes-box"
              tn-comp-role="cube-box"
              tn-ra-link-sink=""
              tn-comp="box"
              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
              tn-uuid=""
              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
              opera-tn-ra-comp="box"
              style={{}}>
              <section
                className="tn-comp-pin tn-comp-style-pin"
                tn-link="compAttr.link"
                tn-sound="compAttr.sound"
                ng-style="compAttr.style | optimizeImageType"
                tn-pre-load-image="compAttr.style.backgroundImage">
                <div
                  className="tn-cube-slot tn-view-cubes tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-group-usage-normal tn-state-active tn-cube-flipper-box"
                  tn-cell-type="group"
                  tn-child-position="absolute"
                  tn-cell="cubes"
                  tn-link="cell.link"
                  ng-style="cell.style | optimizeImageType"
                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                  tn-animate-on-self="true"
                  tn-sound="cell.sound"
                  ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                  opera-tn-ra-cell="box.cubes"
                  style={{}}>
                  {' '}
                  {/* ngRepeat: chd in cellGroupItems track by $index */}
                  <div
                    className="tn-surface tn-cube tn-show-cube ng-scope tn-from-house-reader_paper-cp tn-comp-anim-pin tn-comp-inst tn-cube-inst tn-comp tn-in-cell-state-active on-screen"
                    tn-comp-role="cube"
                    tn-bind-comp-tpl-id="reader_paper-cp:sys/cube-fs"
                    ng-repeat="chd in cellGroupItems track by $index"
                    tn-comp="chd"
                    tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                    tn-uuid=""
                    tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                    tn-comp-index={0}
                    opera-tn-ra-comp="box.cubes:0"
                    style={{}}>
                    {' '}
                    <section
                      className="tn-comp-pin tn-comp-style-pin"
                      tn-link="compAttr.link"
                      tn-sound="compAttr.sound"
                      ng-style="compAttr.style | optimizeImageType"
                      tn-pre-load-image="compAttr.style.backgroundImage">
                      <div
                        className="dock booklet tn-ground-slot tn-view-grounds tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-group-usage-normal tn-state-active tn-vertical-flipper-box"
                        tn-cell-type="group"
                        tn-child-position="absolute"
                        tn-cell="grounds"
                        tn-link="cell.link"
                        ng-style="cell.style | optimizeImageType"
                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                        tn-animate-on-self="true"
                        tn-sound="cell.sound"
                        ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                        opera-tn-ra-cell="box.cubes:0.grounds">
                        {' '}
                        {/* ngRepeat: chd in cellGroupItems track by $index */}
                        <div
                          className="tn-surface tn-ground tn-show-ground ng-scope tn-from-house-reader_paper-cp tn-comp-anim-pin tn-comp-inst tn-ground-inst tn-comp tn-in-cell-state-active page-view-box-30-CfUuY336Sx2JFT1mUZfYCrO5cTAplh6U tn-comp-mode-board on-screen"
                          tn-comp-role="ground"
                          tn-bind-comp-tpl-id="reader_paper-cp:sys/pg-flw"
                          ng-repeat="chd in cellGroupItems track by $index"
                          tn-comp="chd"
                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                          tn-uuid=""
                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                          tn-page-view-box-opera="compConstraint.viewport || _o$.viewport"
                          tn-comp-index={0}
                          opera-tn-ra-comp="box.cubes:0.grounds:0"
                          style={{}}
                          tn-page-stage-size="board_spread">
                          {' '}
                          <section
                            className="tn-comp-pin tn-comp-style-pin"
                            tn-link="compAttr.link"
                            tn-sound="compAttr.sound"
                            ng-style="compAttr.style | optimizeImageType"
                            tn-pre-load-image="compAttr.style.backgroundImage">
                            <div
                              className="tn-layer-slot tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-group-usage-normal tn-state-active"
                              tn-cell-type="group"
                              tn-child-position="absolute"
                              tn-cell="layers"
                              tn-link="cell.link"
                              ng-style="cell.style | optimizeImageType"
                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                              tn-animate-on-self="true"
                              tn-sound="cell.sound"
                              ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                              opera-tn-ra-cell="box.cubes:0.grounds:0.layers">
                              {' '}
                              {/* ngRepeat: chd in cellGroupItems track by $index */}
                              <div
                                className="tn-layer ng-scope tn-from-house-reader_paper-cp tn-comp-anim-pin tn-comp-inst tn-layer-inst tn-comp tn-in-cell-state-active"
                                tn-comp-role="layer"
                                tn-bind-comp-tpl-id="reader_paper-cp:sys/ly-flw"
                                ng-repeat="chd in cellGroupItems track by $index"
                                tn-comp="chd"
                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                tn-uuid=""
                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                tn-comp-index={0}
                                opera-tn-ra-comp="box.cubes:0.grounds:0.layers:0"
                                style={{}}>
                                <section
                                  className="tn-comp-pin tn-comp-style-pin"
                                  tn-link="compAttr.link"
                                  tn-sound="compAttr.sound"
                                  ng-style="compAttr.style | optimizeImageType"
                                  tn-pre-load-image="compAttr.style.backgroundImage">
                                  <div
                                    className="tn-comp-slot tn-cell tn-cell-group tn-child-position-absolute group-empty tn-state-active ng-scope"
                                    tn-cell-type="group"
                                    tn-link="cell.link"
                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                    tn-animate-on-self="true"
                                    tn-ra-wrapper-cell=""
                                    tn-ra-cell="box.cubes:0.grounds:0.layers:0.comps"></div>
                                </section>
                              </div>
                              {/* end ngRepeat: chd in cellGroupItems track by $index */}{' '}
                            </div>
                          </section>
                        </div>
                        {/* end ngRepeat: chd in cellGroupItems track by $index */}{' '}
                      </div>{' '}
                      <div
                        className="dock booklet tn-page-slot tn-view-pages tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-group-usage-normal tn-state-active tn-vertical-flipper-box"
                        tn-cell-type="group"
                        tn-child-position="absolute"
                        tn-cell="pages"
                        tn-link="cell.link"
                        ng-style="cell.style | optimizeImageType"
                        tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                        tn-animate-on-self="true"
                        tn-sound="cell.sound"
                        ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                        opera-tn-ra-cell="box.cubes:0.pages">
                        {' '}
                        {/* ngRepeat: chd in cellGroupItems track by $index */}
                        <div
                          className="tn-surface tn-page tn-show-page ng-scope tn-from-house-reader_paper-cp tn-comp-anim-pin tn-comp-inst tn-page-inst tn-comp tn-in-cell-state-active page-view-box-32-2lN4n7nYzTldsUuekyx2JwhFs5MPteEh tn-comp-mode-flow on-screen"
                          tn-comp-role="page"
                          tn-bind-comp-tpl-id="reader_paper-cp:sys/pg-flw"
                          ng-repeat="chd in cellGroupItems track by $index"
                          tn-comp="chd"
                          tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                          tn-uuid=""
                          tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                          tn-page-view-box-opera="compConstraint.viewport || _o$.viewport"
                          tn-comp-index={0}
                          opera-tn-ra-comp="box.cubes:0.pages:0"
                          style={{}}
                          tn-page-stage-size="flow_scroll">
                          {' '}
                          <section
                            className="tn-comp-pin tn-comp-style-pin"
                            tn-link="compAttr.link"
                            tn-sound="compAttr.sound"
                            ng-style="compAttr.style | optimizeImageType"
                            tn-pre-load-image="compAttr.style.backgroundImage"
                            style={{ padding: '0px 10px' }}>
                            <div
                              className="tn-layer-slot tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-group-usage-normal tn-state-active"
                              tn-cell-type="group"
                              tn-child-position="absolute"
                              tn-cell="layers"
                              tn-link="cell.link"
                              ng-style="cell.style | optimizeImageType"
                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                              tn-animate-on-self="true"
                              tn-sound="cell.sound"
                              ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                              opera-tn-ra-cell="box.cubes:0.pages:0.layers">
                              {' '}
                              {/* ngRepeat: chd in cellGroupItems track by $index */}
                              <div
                                className="tn-layer ng-scope tn-from-house-reader_paper-cp tn-comp-anim-pin tn-comp-inst tn-layer-inst tn-comp tn-in-cell-state-active"
                                tn-comp-role="layer"
                                tn-bind-comp-tpl-id="reader_paper-cp:sys/ly-flw"
                                ng-repeat="chd in cellGroupItems track by $index"
                                tn-comp="chd"
                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                tn-uuid=""
                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                tn-comp-index={0}
                                opera-tn-ra-comp="box.cubes:0.pages:0.layers:0"
                                style={{}}>
                                <section
                                  className="tn-comp-pin tn-comp-style-pin"
                                  tn-link="compAttr.link"
                                  tn-sound="compAttr.sound"
                                  ng-style="compAttr.style | optimizeImageType"
                                  tn-pre-load-image="compAttr.style.backgroundImage">
                                  <div
                                    className="tn-comp-slot tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                    tn-cell-type="group"
                                    tn-link="cell.link"
                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                    tn-animate-on-self="true"
                                    tn-ra-wrapper-cell=""
                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps">
                                    {' '}
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:0">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            backgroundColor: 'rgb(241, 249, 248)',
                                            height: 'auto',
                                            padding: 32
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:0.col1">
                                          <section
                                            className="tn-comp-pin tn-comp-style-pin"
                                            tn-link="compAttr.link"
                                            style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'space-between'
                                            }}>
                                            <div
                                              className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                              tn-uuid=""
                                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                              style={{}}
                                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:0.col1:0">
                                              <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                <div
                                                  tn-cell-type="text"
                                                  tn-link="cell.link"
                                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                  tn-animate-on-self="true"
                                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                  title=""
                                                  className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                  style={{
                                                    textAlign: 'justify',
                                                    fontSize: '156.25%',
                                                    color: 'rgb(56, 87, 60)'
                                                  }}
                                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:0.col1:0.txt1">
                                                  <p>706湾区“心理探索”公益月（2月24日-3月17日）</p>
                                                  <p>&nbsp; &nbsp;—“好好连接，照亮彼此”</p>
                                                </div>
                                              </section>
                                            </div>
                                            <div
                                              tn-cell-type="image"
                                              style={{
                                                maxWidth: '100%',
                                                verticalAlign: 'middle',
                                                display: 'inline-block',
                                                lineHeight: 0
                                              }}
                                              className="tn-cell tn-cell-image tn-state-active ng-scope"
                                              tn-link="cell.link"
                                              tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                              tn-animate-on-self="true"
                                              title=""
                                              tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:1.img1">
                                              <img
                                                className="tn-image-presenter raw-image"
                                                src="/xinliyue/9027f7e5e6207096beb4459d7bacf7a0_sz-125176.png"
                                                style={{
                                                  verticalAlign: 'middle',
                                                  maxWidth: '100%',
                                                  width: '100%'
                                                }}
                                              />
                                            </div>
                                          </section>
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            backgroundPosition: '100% 0%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '187.032%',
                                            backgroundAttachment: 'scroll'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: '100%',
                                                  verticalAlign: 'top',
                                                  alignSelf: 'flex-start',
                                                  flex: '0 0 auto',
                                                  backgroundColor: 'rgba(248, 247, 243, 0.74)'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:0.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{
                                                    position: 'static',
                                                    height: 'auto'
                                                  }}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:1">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center',
                                                      display: 'flex',
                                                      flexFlow: 'row',
                                                      margin: '0px 0px 10px',
                                                      transform: 'translate3d(1px, 0px, 0px)'
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'bottom',
                                                        width: 'auto',
                                                        alignSelf: 'flex-end',
                                                        flex: '0 0 auto',
                                                        minWidth: '5%',
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        padding: 0
                                                      }}
                                                      tn-cell-type="group"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:1.col1">
                                                      {' '}
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ position: 'static' }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:1.col1:0">
                                                        <section
                                                          className="tn-comp-pin tn-comp-style-pin"
                                                          tn-link="compAttr.link"
                                                          style={{
                                                            fontSize: '120%',
                                                            margin: '0px 0%',
                                                            textAlign: 'center'
                                                          }}>
                                                          <div
                                                            style={{
                                                              display: 'inline-block',
                                                              border: '1px solid rgb(246, 139, 109)',
                                                              backgroundColor: 'rgb(246, 139, 109)',
                                                              textAlign: 'center',
                                                              width: '1.8em',
                                                              height: '1.8em',
                                                              lineHeight: '1.8em',
                                                              borderRadius: '100%',
                                                              marginLeft: 'auto',
                                                              marginRight: 'auto',
                                                              fontSize: '135.417%',
                                                              color: 'rgb(255, 255, 255)'
                                                            }}
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:1.col1:0.txt1">
                                                            <p style={{ marginTop: '5px' }}>
                                                              <strong>#1</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>{' '}
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:2">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'justify',
                                                        fontSize: '125%'
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:2.txt1">
                                                      <p style={{ textAlign: 'center' }}>
                                                        <span
                                                          style={{
                                                            color: 'rgb(255, 129, 36)',
                                                            fontSize: '150%'
                                                          }}>
                                                          <strong>缘起</strong>
                                                        </span>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:3">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:2.col1:0.col1:3.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col1:0.col1"
                                              />
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{
                                              position: 'static',
                                              transformOrigin: 'center center',
                                              marginTop: 0,
                                              marginBottom: 0
                                            }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-31px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(1px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:3.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:3.col2:0">
                                            <section style={{ textAlign: 'justify', visibility: 'visible' }}>
                                              <p style={{ textWrap: 'wrap', visibility: 'visible' }}>
                                                <span
                                                  style={{
                                                    fontFamily:
                                                      'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                    letterSpacing: '0.034em',
                                                    visibility: 'visible'
                                                  }}>
                                                  亲爱的小伙伴们，
                                                </span>
                                                <br style={{ visibility: 'visible' }} />
                                              </p>
                                              <p style={{ textWrap: 'wrap', visibility: 'visible' }}>
                                                <span
                                                  style={{
                                                    fontFamily:
                                                      'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                    letterSpacing: '0.034em',
                                                    visibility: 'visible'
                                                  }}>
                                                  <br style={{ visibility: 'visible' }} />
                                                </span>
                                              </p>
                                              <p style={{ visibility: 'visible' }}>见字如面。在2024年的新春之季，向您问好并希望您有一个圆满的一年。</p>
                                              <p style={{ visibility: 'visible' }}>
                                                <br style={{ visibility: 'visible' }} />
                                              </p>
                                              <p style={{ textWrap: 'wrap', visibility: 'visible' }}>
                                                心理健康是我们整体幸福和福祉的重要组成部分。它不仅涉及我们的情感和情绪状态，还关乎我们的人际关系、工作绩效以及生活质量。尤其是身居海外，受到两种文化的影响，我们还需要找到最适合自己的价值观和意义感，这对很多人而言都是心灵上的挑战。
                                                <br style={{ visibility: 'visible' }} />
                                              </p>
                                              <p style={{ textWrap: 'wrap', visibility: 'visible' }}>
                                                <br style={{ visibility: 'visible' }} />
                                              </p>
                                              <p style={{ textWrap: 'wrap', visibility: 'visible' }}>
                                                因此，我们迫切需要最适合自己的心理健康教育和提升自我觉察的能力，以便更好地面对生活、找到生活的意义价值，并让自己感到充实和满足。当谈到心理月活动时，我们不禁为之振奋，因为这正是这一使命的重要一环，是旨在
                                                <strong style={{ visibility: 'visible' }}>促进心理健康和社区支持</strong>
                                                的美好活动。
                                              </p>
                                              <p style={{ textAlign: 'center', visibility: 'visible' }}>
                                                <img
                                                  style={{
                                                    height: 'auto !important',
                                                    visibility: 'visible !important',
                                                    width: '500px !important'
                                                  }}
                                                  src="/xinliyue/640.png"
                                                />
                                              </p>
                                              <p style={{ textWrap: 'wrap' }}>
                                                我在这里真诚的邀请你，一起来共创这次706湾区“心理探索”公益月活动。本次公益心理月由706湾区主办，张铌心理工作室协办。这是一个为期一个月，由心理行业专业人士和实践者共同引领和共创的线上线下内在觉知及治愈的交流集会。
                                              </p>
                                              <p style={{ textWrap: 'wrap' }}>
                                                <br />
                                                我们希望通过这一个月的活动，普及心理教育，帮助小伙伴们提升自我觉察和自我关系的能力，让更多人了解心理健康的重要性，以及如何维护自己的心理健康。
                                                <span
                                                  style={{
                                                    fontFamily:
                                                      'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                    letterSpacing: '0.034em'
                                                  }}>
                                                  同时，我们努力建立一个包容关爱的社区，鼓励人们支持他人，同时也学会支持自己。
                                                </span>
                                                <span
                                                  style={{
                                                    fontFamily:
                                                      'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                    letterSpacing: '0.034em'
                                                  }}>
                                                  最后，我们希望通过这个活动更好地连接他人，聆听他们的经历，学习共情与支持，最终实现心灵的疗愈和与人有意义的连接。
                                                </span>
                                              </p>
                                              <p style={{ textWrap: 'wrap' }}>
                                                <br />
                                              </p>
                                              <p style={{ textWrap: 'wrap' }}>
                                                我们邀请到来自这个领域的专业人士，包括心理咨询和治疗师、教练、正念冥想老师、萨满文化老师，以及心理学实践者等等。他们即了解东方传统和智慧，又受到西方心理科学教育，他们将运用自身的专业知识和自修经验，为大家做公益科普，帮助大家获得结合东西方文化的心理学常识和自我觉察/体验，从而更好地了解自己的内心世界和需求。
                                              </p>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            backgroundPosition: '100% 0%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '187.032%',
                                            backgroundAttachment: 'scroll'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: '100%',
                                                  verticalAlign: 'top',
                                                  alignSelf: 'flex-start',
                                                  flex: '0 0 auto',
                                                  backgroundColor: 'rgba(248, 247, 243, 0.74)'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:0.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{
                                                    position: 'static',
                                                    height: 'auto'
                                                  }}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:1">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center',
                                                      display: 'flex',
                                                      flexFlow: 'row',
                                                      margin: '0px 0px 10px',
                                                      transform: 'translate3d(1px, 0px, 0px)'
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'bottom',
                                                        width: 'auto',
                                                        alignSelf: 'flex-end',
                                                        flex: '0 0 auto',
                                                        minWidth: '5%',
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        padding: 0
                                                      }}
                                                      tn-cell-type="group"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:1.col1">
                                                      {' '}
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ position: 'static' }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:1.col1:0">
                                                        <section
                                                          className="tn-comp-pin tn-comp-style-pin"
                                                          tn-link="compAttr.link"
                                                          style={{
                                                            fontSize: '120%',
                                                            margin: '0px 0%',
                                                            textAlign: 'center'
                                                          }}>
                                                          <div
                                                            style={{
                                                              display: 'inline-block',
                                                              border: '1px solid rgb(246, 139, 109)',
                                                              backgroundColor: 'rgb(246, 139, 109)',
                                                              textAlign: 'center',
                                                              width: '1.8em',
                                                              height: '1.8em',
                                                              lineHeight: '1.8em',
                                                              borderRadius: '100%',
                                                              marginLeft: 'auto',
                                                              marginRight: 'auto',
                                                              fontSize: '135.417%',
                                                              color: 'rgb(255, 255, 255)'
                                                            }}
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:1.col1:0.txt1">
                                                            <p style={{ marginTop: '5px' }}>
                                                              <strong>#2</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>{' '}
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:2">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'justify',
                                                        fontSize: '125%'
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:2.txt1">
                                                      <p style={{ textAlign: 'center' }}>
                                                        <span
                                                          style={{
                                                            color: 'rgb(255, 129, 36)',
                                                            fontSize: '150%'
                                                          }}>
                                                          <strong>706湾区“心理探索”公益月</strong>
                                                        </span>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:3">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:4.col1:0.col1:3.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col1:0.col1"
                                              />
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{
                                              position: 'static',
                                              transformOrigin: 'center center',
                                              marginTop: 0,
                                              marginBottom: 0
                                            }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-31px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(1px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:0">
                                            <section>
                                              <section style={{ textAlign: 'justify' }}>
                                                <p style={{ marginBottom: 15, textWrap: 'wrap' }}>
                                                  <br />
                                                </p>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  本次706湾区“心理探索”公益月以“
                                                  <span style={{ textDecoration: 'underline' }}>好好连接，照亮彼此</span>
                                                  ”为主题，包括了近二十场
                                                  <span style={{ fontSize: 16, letterSpacing: '0.578px', textWrap: 'wrap' }}>丰富多彩的</span>
                                                  线上线下公益活动。
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    其中包括：
                                                  </span>
                                                </p>
                                                <ul className="list-paddingleft-1" style={{ listStyleType: 'disc' }}>
                                                  <li>
                                                    <p style={{ textWrap: 'wrap' }}>
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          letterSpacing: '0.034em'
                                                        }}>
                                                        舞动疗愈
                                                      </span>
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p style={{ textWrap: 'wrap' }}>
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          letterSpacing: '0.034em'
                                                        }}>
                                                        戏剧疗愈等表达性艺术形式
                                                      </span>
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p style={{ textWrap: 'wrap' }}>
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          letterSpacing: '0.034em'
                                                        }}>
                                                        非评判性交流的T- group
                                                      </span>
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p style={{ textWrap: 'wrap' }}>
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          letterSpacing: '0.034em'
                                                        }}>
                                                        了解自己身体和性欲的“愉悦”工作坊
                                                      </span>
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p style={{ textWrap: 'wrap' }}>
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          letterSpacing: '0.034em'
                                                        }}>
                                                        东西方心理和哲学的探讨
                                                      </span>
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p style={{ textWrap: 'wrap' }}>
                                                      <span
                                                        style={{
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          letterSpacing: '0.034em'
                                                        }}>
                                                        ...
                                                      </span>
                                                    </p>
                                                  </li>
                                                </ul>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}
                                                  />
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    这些活动形式将为小伙伴们
                                                  </span>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    提供多样化的体验，帮助大家
                                                  </span>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    更好地了解心理健康，并在安全融洽的氛围中与他人互动。
                                                  </span>
                                                </p>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    <br />
                                                  </span>
                                                </p>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    此外，我们还将在线上举办各个门派心理治疗师和教练的Panel圆桌讨论，帮大家了解治疗师和教练都有什么样的不同技术？
                                                  </span>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    两者的区别究竟是什么？
                                                  </span>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    邀请大家积极参与其中。
                                                  </span>
                                                </p>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    <br />
                                                  </span>
                                                </p>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  <span
                                                    style={{
                                                      fontFamily:
                                                        'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                      letterSpacing: '0.034em'
                                                    }}>
                                                    这将是一个学习和分享的平台，让我们共同探讨心理健康的话题，共同成长，共同支持。
                                                  </span>
                                                </p>
                                              </section>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ maxWidth: '100%', position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                maxWidth: '100%',
                                                justifyContent: 'center'
                                              }}>
                                              <div
                                                tn-cell-type="container"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{
                                                    maxWidth: '100%',
                                                    display: 'inline-block',
                                                    verticalAlign: 'top'
                                                  }}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      maxWidth: '100%',
                                                      textAlign: 'left',
                                                      justifyContent: 'flex-start'
                                                    }}>
                                                    <div
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{
                                                          maxWidth: '100%',
                                                          marginBottom: '-6px'
                                                        }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:0">
                                                        <section
                                                          className="tn-comp-pin tn-comp-style-pin"
                                                          tn-link="compAttr.link"
                                                          style={{
                                                            maxWidth: '100%',
                                                            textAlign: 'left',
                                                            justifyContent: 'flex-start'
                                                          }}>
                                                          <div
                                                            tn-cell-type="container"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:0.col1">
                                                            <div
                                                              className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                              tn-uuid=""
                                                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                              style={{}}
                                                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:0.col1:0">
                                                              <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                                <div
                                                                  tn-cell-type="text"
                                                                  tn-link="cell.link"
                                                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                  tn-animate-on-self="true"
                                                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                  title=""
                                                                  className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                  style={{
                                                                    textAlign: 'justify'
                                                                  }}
                                                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:0.col1:0.txt1">
                                                                  <p>
                                                                    <br />
                                                                  </p>
                                                                </div>
                                                              </section>
                                                            </div>
                                                            <div
                                                              className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                              tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                              tn-uuid=""
                                                              tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                              style={{ maxWidth: '100%' }}
                                                              tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:0.col1:1">
                                                              <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ maxWidth: '100%' }}>
                                                                <div
                                                                  tn-cell-type="text"
                                                                  tn-link="cell.link"
                                                                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                                  tn-animate-on-self="true"
                                                                  tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                                  title=""
                                                                  className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                                  style={{
                                                                    textAlign: 'left',
                                                                    lineHeight: '1em',
                                                                    paddingLeft: 2,
                                                                    paddingRight: 2,
                                                                    fontSize: '125%',
                                                                    maxWidth: '100%'
                                                                  }}
                                                                  tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:0.col1:1.txt1">
                                                                  <p
                                                                    style={{
                                                                      textAlign: 'center'
                                                                    }}>
                                                                    <strong>工作坊概览</strong>
                                                                  </p>
                                                                </div>
                                                              </section>
                                                            </div>
                                                          </div>
                                                        </section>
                                                      </div>
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ display: 'contents' }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:1">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ display: 'contents' }}>
                                                          <div
                                                            tn-cell-type="code-snippet-module"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            className="tn-cell tn-state-active ng-scope"
                                                            style={{ display: 'contents' }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:1.col1:0.col1:1.codeSnippetModule">
                                                            <section
                                                              style={{
                                                                display: 'contents',
                                                                maxWidth: '100%'
                                                              }}>
                                                              <section
                                                                style={{
                                                                  display: 'contents'
                                                                }}>
                                                                <section
                                                                  style={{
                                                                    display: 'contents'
                                                                  }}>
                                                                  <section
                                                                    style={{
                                                                      width: '100%',
                                                                      height: 10,
                                                                      backgroundColor: 'rgba(73, 125, 184, 0.37)'
                                                                    }}>
                                                                    <svg
                                                                      viewBox="0 0 1 1"
                                                                      style={{
                                                                        float: 'left',
                                                                        lineHeight: 0,
                                                                        width: 0
                                                                      }}
                                                                    />
                                                                  </section>
                                                                </section>
                                                              </section>
                                                            </section>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:5.col2:3">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                marginTop: 10,
                                                marginBottom: 10,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:5.col2:3.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/260d58282d1a7548a214dd4150f98aed-sz_2975292.jpeg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                      <section
                                        style={{
                                          display: 'inline-block',
                                          verticalAlign: 'top',
                                          width: 'auto',
                                          marginLeft: '35px',
                                          height: 'auto',
                                          borderLeft: '1px solid rgb(183, 212, 187)',
                                          zIndex: 0,
                                          padding: '20px 10px 20px 30px'
                                        }}>
                                        <section>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span
                                              style={{
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.578px'
                                              }}>
                                              <span
                                                style={{
                                                  color: 'rgb(0, 0, 0)',
                                                  fontFamily: '"PingFang SC"',
                                                  fontSize: 16,
                                                  textWrap: 'wrap',
                                                  letterSpacing: '0.578px'
                                                }}
                                                data-style='color: rgb(0, 0, 0); font-family: "PingFang SC"; font-size: 16px; text-wrap: wrap; letter-spacing: 0.578px;'
                                                className="js_darkmode__18">
                                                本次活动的三场Panel圆桌讨论是纯公益活动，有兴趣的小伙伴donate
                                                $10或者在2024年2月11日前转发到朋友圈集齐10个赞即可参与（与此同时还能有三场线上活动的报名机会）。
                                              </span>
                                            </span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span
                                              style={{
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.578px'
                                              }}>
                                              <span
                                                style={{
                                                  color: 'rgb(0, 0, 0)',
                                                  fontFamily: '"PingFang SC"',
                                                  fontSize: 16,
                                                  textWrap: 'wrap',
                                                  letterSpacing: '0.578px'
                                                }}
                                                data-style='color: rgb(0, 0, 0); font-family: "PingFang SC"; font-size: 16px; text-wrap: wrap; letter-spacing: 0.578px;'
                                                className="js_darkmode__19">
                                                <br />
                                              </span>
                                            </span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span
                                              style={{
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.578px'
                                              }}>
                                              <span
                                                style={{
                                                  color: 'rgb(0, 0, 0)',
                                                  fontFamily: '"PingFang SC"',
                                                  fontSize: 16,
                                                  textWrap: 'wrap',
                                                  letterSpacing: '0.578px'
                                                }}
                                                data-style='color: rgb(0, 0, 0); font-family: "PingFang SC"; font-size: 16px; text-wrap: wrap; letter-spacing: 0.578px;'
                                                className="js_darkmode__20">
                                                由于线下单场活动名额十分有限，目前我们只对donor开放，
                                              </span>
                                              <span
                                                style={{
                                                  color: 'rgb(0, 0, 0)',
                                                  fontFamily: '"PingFang SC"',
                                                  fontSize: 16,
                                                  textWrap: 'wrap',
                                                  letterSpacing: '0.578px'
                                                }}
                                                data-style='color: rgb(0, 0, 0); font-family: "PingFang SC"; font-size: 16px; text-wrap: wrap; letter-spacing: 0.578px;'
                                                className="js_darkmode__21">
                                                欢迎前往givebutter捐款提前锁定位置。
                                              </span>
                                            </span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <br />
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span
                                              style={{
                                                fontFamily: '"PingFang SC"',
                                                color: 'rgb(0, 0, 0)',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                              className="js_darkmode__22">
                                              <span
                                                style={{
                                                  fontSize: 16,
                                                  textWrap: 'wrap',
                                                  fontFamily: '"PingFang SC"',
                                                  color: 'rgb(0, 0, 0)',
                                                  letterSpacing: 'normal'
                                                }}
                                                data-style='font-size: 16px; text-wrap: wrap; font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                                className="js_darkmode__23">
                                                本
                                              </span>
                                              <span
                                                style={{
                                                  fontSize: 16,
                                                  textWrap: 'wrap',
                                                  fontFamily: '"PingFang SC"',
                                                  color: 'rgb(0, 0, 0)',
                                                  letterSpacing: 'normal'
                                                }}
                                                data-style='font-size: 16px; text-wrap: wrap; font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                                className="js_darkmode__24">
                                                次参与的老师，为了支持募款，
                                              </span>
                                              <span
                                                style={{
                                                  fontSize: 16,
                                                  textWrap: 'wrap',
                                                  fontFamily: '"PingFang SC"',
                                                  color: 'rgb(0, 0, 0)',
                                                  letterSpacing: 'normal'
                                                }}
                                                data-style='font-size: 16px; text-wrap: wrap; font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                                className="js_darkmode__25">
                                                公布的是工作坊公益价。所有的线下工作坊，我们采用
                                              </span>
                                              Sliding Scale灵活定价的
                                            </span>
                                            <span
                                              style={{
                                                fontFamily: '"PingFang SC"',
                                                color: 'rgb(0, 0, 0)',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                              className="js_darkmode__26">
                                              模式：如果经济状况紧张，可以支付
                                            </span>
                                            <span
                                              style={{
                                                fontFamily: '"PingFang SC"',
                                                color: 'rgb(0, 0, 0)',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                              className="js_darkmode__27">
                                              lower end of the sliding scale，
                                            </span>
                                            <span
                                              style={{
                                                fontFamily: '"PingFang SC"',
                                                color: 'rgb(0, 0, 0)',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                              className="js_darkmode__28">
                                              如果您想支持老师的公益心，可
                                            </span>
                                            <span
                                              style={{
                                                fontFamily: '"PingFang SC"',
                                                color: 'rgb(0, 0, 0)',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                              className="js_darkmode__29">
                                              以支持
                                            </span>
                                            <span
                                              style={{
                                                fontFamily: '"PingFang SC"',
                                                color: 'rgb(0, 0, 0)',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                              className="js_darkmode__30">
                                              higher end of the sliding scale（例：$30~60您可以按您的情况支付$30到$60之间的数字）
                                            </span>
                                            <span
                                              style={{
                                                fontFamily: '"PingFang SC"',
                                                color: 'rgb(0, 0, 0)',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='font-family: "PingFang SC"; color: rgb(0, 0, 0); letter-spacing: normal;'
                                              className="js_darkmode__31">
                                              。
                                            </span>
                                            <span
                                              style={{
                                                color: 'rgb(0, 0, 0)',
                                                fontFamily: '"PingFang SC"',
                                                letterSpacing: 'normal'
                                              }}
                                              data-style='color: rgb(0, 0, 0); font-family: "PingFang SC"; letter-spacing: normal;'
                                              className="js_darkmode__32">
                                              心理月感恩每位老师的倾力支持。
                                            </span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <br />
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span
                                              style={{
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em'
                                              }}>
                                              倘若您因为时间/地点不合适无法参与这次706湾区心理月的活动，也欢迎捐款并告知我们您对哪个活动有兴趣：
                                            </span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span
                                              style={{
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em',
                                                fontSize: 15,
                                                textDecoration: 'underline',
                                                color: 'rgb(0, 128, 255)'
                                              }}>
                                              <a href="https://forms.gle/nqqG7jcBPcVwkgeH7">https://forms.gle/nqqG7jcBPcVwkgeH7</a>
                                            </span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span
                                              style={{
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em'
                                              }}>
                                              未来如果在706心理月基金的支持下这些活动有加场，您在活动报名群里的话我们也会优先考虑您的意向（捐款超过$20以上的小伙伴都会被邀请入活动报名群）。
                                            </span>
                                          </p>
                                        </section>
                                        <section style={{}}>
                                          <section style={{ textAlign: 'center' }}>
                                            <section>
                                              <section style={{ display: 'inline-block', verticalAlign: 'top' }}>
                                                <section style={{ textAlign: 'left' }}>
                                                  <section>
                                                    <section style={{ marginBottom: '-6px' }}>
                                                      <section>
                                                        <section style={{ textAlign: 'justify' }}>
                                                          <p style={{ textWrap: 'wrap' }}>
                                                            <br />
                                                          </p>
                                                        </section>
                                                        <section style={{}}>
                                                          <section
                                                            style={{
                                                              lineHeight: '1em',
                                                              paddingLeft: 2,
                                                              paddingRight: 2,
                                                              fontSize: 20
                                                            }}>
                                                            <p style={{ textAlign: 'center' }}>
                                                              <strong>706心理月基金</strong>
                                                            </p>
                                                          </section>
                                                        </section>
                                                      </section>
                                                    </section>
                                                    <section style={{ display: 'contents' }}>
                                                      <section style={{ display: 'contents' }}>
                                                        <section style={{ display: 'contents' }}>
                                                          <section style={{ display: 'contents' }}>
                                                            <section style={{ display: 'contents' }}>
                                                              <section style={{ display: 'contents' }}>
                                                                <section
                                                                  style={{
                                                                    width: '100%',
                                                                    height: 10,
                                                                    backgroundColor: 'rgba(73, 125, 184, 0.37)'
                                                                  }}
                                                                  data-style="width: 100%; height: 10px; background-color: rgba(73, 125, 184, 0.37);"
                                                                  className="js_darkmode__33">
                                                                  <svg viewBox="0 0 1 1" style={{ float: 'left', lineHeight: 0, width: 0 }} />
                                                                </section>
                                                              </section>
                                                            </section>
                                                          </section>
                                                        </section>
                                                      </section>
                                                    </section>
                                                  </section>
                                                </section>
                                              </section>
                                            </section>
                                          </section>
                                        </section>
                                        <p style={{ textWrap: 'wrap' }}>
                                          <br />
                                        </p>
                                        <section>
                                          <p style={{ textWrap: 'wrap' }}>
                                            本次公益心理月活动的主要目的是为706心理月基金筹款。706
                                            <span
                                              style={{
                                                textAlign: 'start',
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em'
                                              }}>
                                              心理月基金是706北美
                                            </span>
                                            <span
                                              style={{
                                                textAlign: 'start',
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em'
                                              }}>
                                              专门为本次心理月
                                            </span>
                                            <span
                                              style={{
                                                textAlign: 'start',
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em'
                                              }}>
                                              和未来心理相关的活动设立的基金。
                                            </span>
                                            <span
                                              style={{
                                                textAlign: 'start',
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em'
                                              }}>
                                              将
                                            </span>
                                            <span
                                              style={{
                                                textAlign: 'start',
                                                fontFamily:
                                                  'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                letterSpacing: '0.034em'
                                              }}>
                                              会用于负担本次心理月的运营开支、以及支持706和湾区未来的心理科普相关的活动。
                                            </span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <br />
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            如果你想直接给706心理月基金捐款，支持706和湾区的心理科普事业、并且获得本次心理月活动参与福利的话，欢迎
                                            <span style={{ textDecoration: 'none' }}>
                                              <strong>扫下面二维码👇</strong>
                                            </span>
                                            或
                                            <strong>
                                              <span style={{ textDecoration: 'none' }}>复制链接</span>
                                            </strong>
                                            到浏览器：
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>
                                            <span style={{ color: 'rgb(0, 128, 255)', textDecoration: 'underline' }}>www.givebutter.com/706sf-psychology</span>
                                          </p>
                                          <p style={{ textWrap: 'wrap' }}>来支持我们～</p>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:81.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'center' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:81.col2:2.txt1">
                                                <img src="/xinliyue/qr-code.png" width={'35%'} />
                                              </div>
                                            </section>
                                          </div>{' '}
                                          <p style={{ textWrap: 'wrap' }}>
                                            <br />
                                          </p>
                                        </section>
                                        <p style={{ textWrap: 'wrap' }}>
                                          <span
                                            style={{
                                              fontFamily: 'Matter, sans-serif',
                                              fontSize: 16,
                                              fontVariantLigatures: 'no-contextual',
                                              letterSpacing: 'normal',
                                              textAlign: 'start',
                                              textWrap: 'wrap',
                                              backgroundColor: 'rgb(255, 255, 255)',
                                              color: 'rgb(0, 0, 0)'
                                            }}
                                            data-style="font-family: Matter, sans-serif; font-size: 16px; font-variant-ligatures: no-contextual; letter-spacing: normal; text-align: start; text-wrap: wrap; background-color: rgb(255, 255, 255); color: rgb(0, 0, 0);"
                                            className="js_darkmode__34">
                                            706北美是一个基于中文社群的501c3非营利组织，致力于建立新型社区和探索面向未来的生活方式，并促进其成员之间发展真实的联结、提供心理支持和分享共同生活空间。如果您在美国，您的每笔捐款也会享有免税资格。
                                          </span>
                                        </p>
                                        <p style={{ textWrap: 'wrap' }}>
                                          <br />
                                        </p>
                                        <p
                                          style={{
                                            marginBottom: 0,
                                            fontSize: 16,
                                            letterSpacing: '0.578px',
                                            textWrap: 'wrap'
                                          }}>
                                          以下是本次心理月的活动详情。
                                          <strong
                                            style={{
                                              letterSpacing: '0.578px',
                                              fontFamily:
                                                'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                            }}>
                                            活动更多详情，及线下工作坊剩余名额，请通过活动网站了解实时更新：
                                          </strong>
                                        </p>
                                        <section
                                          style={{
                                            marginBottom: 0,
                                            fontSize: 16,
                                            letterSpacing: '0.578px',
                                            textWrap: 'wrap'
                                          }}>
                                          <p>
                                            <span style={{ color: 'rgb(0, 128, 255)', textDecoration: 'underline' }}>
                                              <a href="https://nizhangtherapy.com/xinliyue">https://nizhangtherapy.com/xinliyue</a>
                                            </span>
                                          </p>
                                        </section>
                                      </section>
                                    </div>
                                    <div>
                                      <a href="/xinliyue/ni_zhang_workshop.jpg" target="_blank">
                                        <img src="/xinliyue/ni_zhang_workshop.jpg" width="14%" />
                                      </a>
                                      <a href="/xinliyue/shaoheng.jpg" target="_blank">
                                        <img src="/xinliyue/shaoheng.jpg" width="14%" />
                                      </a>
                                      <a href="/xinliyue/xuel.jpg" target="_blank">
                                        <img src="/xinliyue/xuel.jpg" width="14%" />
                                      </a>
                                      <a href="/xinliyue/round_table.jpg" target="_blank">
                                        <img src="/xinliyue/round_table.jpg" width="14%" />
                                      </a>
                                      <a href="/xinliyue/mengmeng.jpg" target="_blank">
                                        <img src="/xinliyue/mengmeng.jpg" width="14%" />
                                      </a>
                                      <a href="/xinliyue/ni_xuel.jpg" target="_blank">
                                        <img src="/xinliyue/ni_xuel.jpg" width="14%" />
                                      </a>
                                      <a href="/xinliyue/burnout.jpg" target="_blank">
                                        <img src="/xinliyue/burnout.jpg" width="14%" />
                                      </a>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            backgroundPosition: '100% 0%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '187.032%',
                                            backgroundAttachment: 'scroll'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: '100%',
                                                  verticalAlign: 'top',
                                                  alignSelf: 'flex-start',
                                                  flex: '0 0 auto',
                                                  backgroundColor: 'rgba(248, 247, 243, 0.74)'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:0.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{
                                                    position: 'static',
                                                    height: 'auto'
                                                  }}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:1">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center',
                                                      display: 'flex',
                                                      flexFlow: 'row',
                                                      margin: '0px 0px 10px',
                                                      transform: 'translate3d(1px, 0px, 0px)'
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'bottom',
                                                        width: 'auto',
                                                        alignSelf: 'flex-end',
                                                        flex: '0 0 auto',
                                                        minWidth: '5%',
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        padding: 0
                                                      }}
                                                      tn-cell-type="group"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:1.col1">
                                                      {' '}
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ position: 'static' }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:1.col1:0">
                                                        <section
                                                          className="tn-comp-pin tn-comp-style-pin"
                                                          tn-link="compAttr.link"
                                                          style={{
                                                            fontSize: '120%',
                                                            margin: '0px 0%',
                                                            textAlign: 'center'
                                                          }}>
                                                          <div
                                                            style={{
                                                              display: 'inline-block',
                                                              border: '1px solid rgb(246, 139, 109)',
                                                              backgroundColor: 'rgb(246, 139, 109)',
                                                              textAlign: 'center',
                                                              width: '1.8em',
                                                              height: '1.8em',
                                                              lineHeight: '1.8em',
                                                              borderRadius: '100%',
                                                              marginLeft: 'auto',
                                                              marginRight: 'auto',
                                                              fontSize: '135.417%',
                                                              color: 'rgb(255, 255, 255)'
                                                            }}
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:1.col1:0.txt1">
                                                            <p style={{ marginTop: '5px' }}>
                                                              <strong>#3</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>{' '}
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:2">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'justify',
                                                        fontSize: '125%'
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:2.txt1">
                                                      <p style={{ textAlign: 'center' }}>
                                                        <span
                                                          style={{
                                                            color: 'rgb(255, 129, 36)',
                                                            fontSize: '150%'
                                                          }}>
                                                          <b>活动详情</b>
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:3">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:6.col1:0.col1:3.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <strong>01</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>舞动疗愈体验坊</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  舞动是一种多元的、艺术式的自我探索、转化和表达方式。通过移动肢体、进入节奏和转化情感来扩展内在空间（Inner
                                                  Space）。个人的感知、直觉、体感、记忆和念头等，在舒适的节奏中被唤起、调动和重新整合。体验过舞动治疗的人，通常在一场工作坊后，会感到轻松、释放并有所领悟。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  本次工作坊，你将在专业人士的带领下，通过身体动作、躯体雕像等练习，通过绘画和舞动“自我图像”，与自我对话，通过艺术表达的形式，了解目前和自我的关系，并创造、探索新一年与自我关系的可能性。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>此工作坊不需要任何舞动或绘画经验：）</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:7.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:7.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：2月24日 2 pm - 4 pm&nbsp; &nbsp;&nbsp;</p>
                                                <p>地点：SF</p>
                                                <p>工作坊人数：20</p>
                                              </div>
                                            </section>
                                          </div>
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/8d6d7f611fc01f7f55beb338306bb837-sz_115233.jpeg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '100%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>张铌</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:8.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:8.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:9">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:9.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:9.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  fontSize: '87.5%',
                                                  textAlign: 'justify',
                                                  color: 'gray'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:9.col1:0.txt1">
                                                <p>
                                                  加州心理治疗师，躯体心理治疗研究生专业（Somatic
                                                  Psychotherapy）。目前在湾区非营利组织与伴侣和个人工作。张铌相信每个人生下来内在具足，富有潜能和影响力。她希望可以帮助人们感受到、看到和理解到自己的内性和潜能，通过与自我本性连接，体验自我和生活本来就有的富足和圆满。张铌长期研习躯体心理治疗、东方哲学、生态心理，她不仅使用西方科学的思维来理解内心困境，也擅长运用东方古老智慧来治愈和护持心灵。前合光禅修中心创始人。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>02</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>倾听你的情绪，表达你的渴望 — 身心体验坊</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  当你探访内心感受和渴望的时候，你能准确并自如的认知和表达自己的情绪和渴望吗？或者，你习惯了有意淡化自己的感受，礼貌性地不去要求，甚至牺牲自己去照顾他人呢？
                                                </p>
                                                <p>
                                                  这个由躯体心理治疗师（张铌）和关系心理治疗师(Xuel)共同带领，在舞动、玩耍、实验中带大家深入联结自己内在容易被忽视的声音、情绪、渴望。验坊结束后，你可能会惊喜，发现新的一个自己。
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:10.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:10.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月9日 1 pm - 4 pm</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：20</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/8d6d7f611fc01f7f55beb338306bb837-sz_115233.jpeg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>张铌</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:11.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:11.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:12.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:12.col1:0.txt1">
                                                <p>
                                                  加州心理治疗师，躯体心理治疗研究生专业（Somatic
                                                  Psychotherapy）。目前在湾区非营利组织与伴侣和个人工作。张铌相信每个人生下来内在具足，富有潜能和影响力。她希望可以帮助人们感受到、看到和理解到自己的内性和潜能，通过与自我本性连接，体验自我和生活本来就有的富足和圆满。张铌长期研习躯体心理治疗、东方哲学、生态心理，她不仅使用西方科学的思维来理解内心困境，也擅长运用东方古老智慧来治愈和护持心灵。前合光禅修中心创始人。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/c010be6b1d58a969aea5cca2363fe50c-sz_322022.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>雪凌 (Xuel Sun)</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:13.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:13.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:14.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:14.col1:0.txt1">
                                                <p>
                                                  创伤/关系/超个人心理咨询师，直觉向导，团体带领者，身体工作者。感性理性灵性兼备，跨界探索的自由灵魂。毕业于加州整合学院整合心理咨询硕士学位，克莱蒙麦肯纳学院计算机本科学位，前Salesfore
                                                  软件工程师，现工作于加州一所ngo心理咨询中心。热衷于推广身体感官探索、超个人心理学、爱与觉醒、conscious sexuality等议题的学术研究和线下社群
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>03</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>Panel：积极心理学 X 神学：我们如何生长出「心灵核心力量」？</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>正如核心肌肉力量是强健体魄的基础，品格与信仰构成了一个人健康心灵的核心力量。</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  在青年时期，当一个人面对无数种价值理念、生活方式、职业和人生方向的选择，该如何探索和发现自己从内心深处认可的价值观和信仰，并且在身体力行的实践中锻造个人品格的力量呢？
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>为了生长出“心灵核心力量”，我们将目光投向积极心理学和基督教神学的交汇点。</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  积极心理学的研究涉及韧性、品格发展和整体的人类福祉，为我们提供了诸多基于科学实证的见解与实践工具。与此同时，基督教的神学教导提供了经得起时间考验的深刻道德和灵性指导。通过将这些视角融合在一起，我们的目标是创造一个全面的理解，了解如何为生活在多元价值观社会的青年提供应对当前挑战所需的工具。
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:15.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:15.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月2日 1 pm - 3 pm&nbsp; &nbsp;&nbsp;</p>
                                                <p>地点：线上</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/5d96a7ab6d29121dfd4206961b2ecf21-sz_169899.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>邵恒</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:16.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:16.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  主持人
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:17">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:17.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:17.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:17.col1:0.txt1">
                                                <p>清华大学积极心理学认证指导师，关注积极心理学与神学的结合实践。</p>
                                                <p>曾任罗辑思维副总裁，在得到APP开设日更专栏《邵恒头条》，总收听量超过3亿。毕业于哈佛大学政治系。</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:18">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:18.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/8af55c87e49bec39bf57d33869559a2b-sz_83936.PNG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:18.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:18.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <b>蔡景辉</b>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:18.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:18.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:18.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>Panel嘉宾</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:19">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:19.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:19.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:19.col1:0.txt1">
                                                <p>三藩市金门基督教会主任牧师，凯撒医院特约临终关怀院牧，斯坦福大学纪念教堂认可证婚牧师。</p>
                                                <p>
                                                  蔡景辉牧师出生于香港，1974年到美国读大学，先后从罗彻斯特大学和罗彻斯特理工学院取得电机工程与计算机科学学位。2004年开始在波士顿郊区华人圣经教会全职担任牧师，2014年加入三藩市金门教会担任主任牧师至今。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>04</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>二? 还是 不二?&nbsp;--- 后殖民语境下以汉语为母语的个体生命体验与文化身份认同问题的浅思</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  对于我们这些在美国生活的汉语母语者，生活经历和‘我是谁’的自我认知无疑受到了更为多元和复杂的影响。那么，让我们一起探索这个主题，看看我们的语言和文化是如何帮助我们定义自己，会遇到哪些或有趣或棘手的挑战。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  在这个讲座的两个小时里，聊聊二元论，说说后殖民理论和现实那点儿事儿。也会有互动游戏练习。语言是帮助人认识世界和认识自我的最基本核心工具之一，思考琢磨一下我们的语言，是如何帮助我们见天地，见自己的。反过来说，语言又是如何阻止我们看到那些看不到的天地，看不到的自己。所谓的那些
                                                  unknown unknowns。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>一起在这场知识与情感的碰撞中，寻找真实的自我。打破只缘身在此山中的迷思，给人生的修行添一个新维度。再次发问：我是谁，谁是我？</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:20.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:20.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月17日</p>
                                                <p>地点：线上</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/7f5495d1d60397217133b00292fabad9-sz_190192.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>River Lu, PhD</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:21.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:21.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:22">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:22.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:22.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:22.col1:0.txt1">
                                                <p>
                                                  River Lu，心理学博士，前美国能源部高级顾问，前杜克能源中国区总监，2014年获美国知名智库Center For America
                                                  Progress的中美关系青年领袖之一，华人精英组织百人会(C100)
                                                  2018年度下一代领袖(NGL)成员。2016年开始转向文化哲学的研究。主要专注思考东西方文化异同以及后殖民时代下华人文化身份认同和身心合一的修行法门。2022年和好友在湾区创办非盈利公益组织
                                                  As We Are，旨在为亚裔年轻人提供身心灵健康方面的内容，活动和有效的工具。River同时还在帮助一些跨种族的年轻夫妻或伴侣提供相处之道的咨询。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <strong>05</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>探寻女性与子宫的关系</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col2:1.txt1">
                                                <p>
                                                  简介：
                                                  <br />
                                                </p>
                                                <p>
                                                  子宫是女性独特的生理器官，也是一个很少被谈起的身体部位。似乎只要TA不疼，就被忽略了。或是觉得这是一个累赘、麻烦的器官，不论是月经，还是生育，都与子宫纠缠不清……
                                                </p>
                                                <p>
                                                  让我们在工作坊中，创造一个安全的环境，从女性视角，而不是医学视角，来探索和了解子宫，从而探索自己与这个独特器官的关系、和身体的关系、和性别的关系，或是通过体验环节，探寻任何你感受到的与子宫有关的话题与关系。
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:23.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:23.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月16日</p>
                                                <p>地点：Oakland + 线上</p>
                                                <p>工作坊人数：20（线下）</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/333ebfce556f824c8f0be046aac7e21b-sz_3477601.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>李丁丁</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:24.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:24.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:25">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:25.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:25.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:25.col1:0.txt1">
                                                <p>HB®催眠分娩生育教育讲师、培训师；</p>
                                                <p>
                                                  两个孩子的妈妈，在亲历自然美好的催眠分娩后，从高校辞职，致力于生育教育事业十年。目前就读于CIIS加州整合学院身心心理学专业，并在Center for Somatic
                                                  Psychotherapy完成心理治疗师实习工作。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>06</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>在表达艺术中发展自我慈悲</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  在当前充斥社交媒体的文化中，我们很容易依赖生活中其他人的肯定，而非从自己的内心寻找肯定。当我们学会以仁爱之心对待自己时，就会加强满足自身情感需求的能力，以及提升面对压力、挑战和痛苦的韧性和积极心态。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  让我们在一个开放、鼓励、支持的环境中，通过一系列的表达艺术活动（包括绘画、写作、舞动等），了解自我感受、培养自我认知、发展自我肯定，温暖而深刻地为自己铺设一条通往自我慈悲的道路。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>此工作坊无需您有任何艺术经验。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:26.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:26.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：2月25日 2 pm - 4:30 pm&nbsp; &nbsp;&nbsp;</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：15</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/4a3a762b269dd3ffde640fe54e42efd3-sz_313085.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>韦华</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:27.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:27.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:28">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:28.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:28.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:28.col1:0.txt1">
                                                <p>
                                                  加州整合大学（CIIS）应用心理学硕士项目讲师，CIIS东西方心理学博士项目在读，CIIS表达艺术治疗专业硕士。心理咨询师，表达艺术治疗师。艺术家，著有治愈系绘画书《喵星人养成计划》。禅修践行者，超过3500小时冥想实修经验。
                                                </p>
                                                <p>
                                                  韦华致力于结合多模式艺术创作活动、西方心理学专业知识、以及东方禅修智慧，来引领人们实现自我接纳和成长，转化创伤，超越生活中的痛苦和阻碍，开发和发展内在潜能，连接、激活创造力，绽放真我光芒，从而过上充满爱、自由自在、自得其乐的幸福快乐生活。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <strong>07</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>Breath work呼吸疗法：情绪排毒与释放</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>体验古老智慧与现代科学结合的呼吸疗法。通过有意识的控制呼吸，影响“交感神经”“副交感神经”与“中枢神经系统。“带动“”化解”大脑和身体中储存的压力和情绪。</p>
                                                <p>大脑开始感受到放松和安全，或战或逃的反应也将会减少，身体也可以重新正常运作，开启自我疗愈的功能</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>适用人群：任何感到紧张，压力大，焦虑，需要有一些情绪释放的人群。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:29.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:29.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月9日 10 am - 11 am</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：20</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/be67bfcc5f085fccf5733ab61a62940e-sz_2102864.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>微微</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:30.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:30.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:31">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:31.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:31.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:31.col1:0.txt1">
                                                <p>
                                                  认证冥想教练，内观，能量疗愈师，颂钵疗愈师，呼吸疗法治疗师和西塔疗愈师。同时也是一名8年的修行者。在学习了很多不同风格，不同门派的疗法后，对疗愈和人类精神有独特和深入的理解，将探索内心自我的艺术与实用工具融合在一起。希望支持大家看见内在的智慧、力量和平静，协助他人创造更充实、更有意义的生活。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <strong>08</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>颂钵疗愈-深度放松减压</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>音钵冥想是最古老的疗愈方法之一，通过声波帮大家深层放松脑神经，释放压力，疏通身体淤堵，提升睡眠，促进身体内部的平衡和自愈能力</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>颂钵通过共振的方式，可以刺激大脑的α波产生，这种波动与深层冥想状态接近，以疗愈身心，让人头脑平静清晰，缓解身体肌肉与心灵上的焦虑与紧张。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:32.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:32.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：2月25日 10 am - 11 am</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：20</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/d8789c008a409b447e385856477dcd99-sz_893304.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>P</strong>
                                                        <strong>haedra</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:33.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:33.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:34">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:34.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:34.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:34.col1:0.txt1">
                                                <p>
                                                  治疗师，接受凯尔特文化，美洲原住民萨满训练超过25年。她擅长整合四个能量体:身体，心理，情感和精神，通过运用原始能量，支持人们找到目标，获得清晰觉知，克服过去创伤的影响，找到方向。她在北美创办女性协会，为500强高管提供私人培训。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <strong>09</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>T-Group Crush Course - 如何与他人建立深度连接</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col2:1.txt1">
                                                <p>
                                                  <strong>
                                                    <br />
                                                  </strong>
                                                </p>
                                                <p>
                                                  <strong>T-Group是什么</strong>
                                                </p>
                                                <p>T-Group是Training Group的缩写。 它是一个沟通实验小组，旨在提高参与者自我觉知，锻炼与他人建立深度连接的能力。</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  在对话中，我们鼓励真诚真实（Authentic），倾听（Listening），分享（Sharing）。觉察自己，感知他人。在活动之后，你可能会对自己，对他人，对沟通，有更深层的感悟。
                                                </p>
                                                <p>注：完整的T Group体验需要8小时，甚至更长。重点在于对话练习。本次活动为T-Group的crush version，因为时间限制，侧重点会在于知识分享和互动游戏。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:35.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:35.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月2日 3 pm - 6 pm</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：10</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/55c9517d10a7c326a75f7b0d87de645a-sz_150035.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <b>宋博</b>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:36.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:36.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:37">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:37.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:37.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:37.col1:0.txt1">
                                                <p>心理学实践者，有趣的人。</p>
                                                <p>组织T-Group，是他让这个世界变好一点的一个方式。举办线下T-Group活动十余场。</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>10</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>我要下班!——当Burnout遇到戏剧疗愈</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  在这个戏剧疗愈的体验活动中，我们邀请您走进一个可以尽情玩耍与表达的空间。通过参与者在戏剧游戏中的互动和支持，减轻日常生活及职场中固有角色所带来的压力和倦怠感，为日常挑战提供新的视角。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  <strong>活动目的：</strong>
                                                </p>
                                                <ul style={{ listStyleType: 'disc' }}>
                                                  <li>
                                                    <p>在轻松玩耍中提升自我觉察水平，减少对身心倦怠的忽视。&nbsp;</p>
                                                  </li>
                                                  <li>
                                                    <p>在游戏中体验角色转换，增加生命体验丰富性和多样性，减少对单一角色的过度认同。</p>
                                                  </li>
                                                  <li>
                                                    <p>通过戏剧形式尝试表达不同的情感，释放潜藏的压力和疲劳。</p>
                                                  </li>
                                                </ul>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  <strong>谁适合参与？</strong>
                                                </p>
                                                <p>希望通过创意方式减轻压力的个人都欢迎参与。无需任何表演经验。欢迎害羞的朋友。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:38.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:38.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月3日 2 pm - 4 pm</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：12</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:39">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:39.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/9ab9e25de27e82cf0d0243de89e7f827-sz_172001.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:39.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:39.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>Reina</strong>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:39.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:39.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:39.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:40">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:40.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:40.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:40.col1:0.txt1">
                                                <p>毕业于加州整合大学心理咨询专业，戏剧治疗方向，现服务于湾区一家社区心理咨询诊所，学习并实践着童年创伤和集体创伤的疗愈。</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:41">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:41.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/47b098dececb8801d5a49cb70d84f834-sz_232591.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:41.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:41.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>沨湄</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:41.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:41.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:41.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:42">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:42.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:42.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:42.col1:0.txt1">
                                                <p>
                                                  毕业于加州整合大学(CIIS)心理咨询专业，戏剧治疗方向。心理治疗师实习期间服务于加州湾区的一家支持家暴幸存者(也叫亲密关系暴力幸存者)的非营利机构。工作中，聚焦探索和实践在创伤疗愈中，如何运用戏剧和其他艺术形式中的“具体”，“允许”和“行动”的力量。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>11</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>启灵、疗愈与觉醒</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  这不是一个标准的工作坊，而是一场深度探讨会：Xuel
                                                  将基于她六年以上研究不同形式心灵药理疗法的丰富经验，分享其对心理治疗、自我成长及灵性发展的全面见解。在这次分享中，Xuel
                                                  将结合参与者的兴趣点，深入探讨她的学术研究成果、社会观察洞见以及个人深层次的感悟。可能探讨的议题包括：
                                                </p>
                                                <p>* 多角度认知意识状态改变(ASC)&nbsp;</p>
                                                <p>* 不同类型心灵药理疗法的效用、特性及适宜人群</p>
                                                <p>* 心灵药理治疗与传统植物药物仪式的对比</p>
                                                <p>* 如何预防及融合心灵创伤和自我认知的转变</p>
                                                <p>* 心灵药理治疗在促进灵性觉醒过程中可能扮演的角色：铺路砖或绊脚石</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:43.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:43.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月2日 10 am - 12 pm</p>
                                                <p>地点：线上</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:44">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:44.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/c010be6b1d58a969aea5cca2363fe50c-sz_322022.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:44.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:44.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>雪凌 (Xuel Sun)</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:44.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:44.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:44.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:45">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:45.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:45.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:45.col1:0.txt1">
                                                <p>
                                                  创伤/关系/超个人心理咨询师，直觉向导，团体带领者，身体工作者。感性理性灵性兼备，跨界探索的自由灵魂。毕业于加州整合学院整合心理咨询硕士学位，克莱蒙麦肯纳学院计算机本科学位，前Salesfore
                                                  软件工程师，现工作于加州一所ngo心理咨询中心。热衷于推广身体感官探索、超个人心理学、爱与觉醒、conscious sexuality等议题的学术研究和线下社群
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>12</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>带着人生意愿出发，深入人生意义之旅</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  在快节奏的硅谷生活中，大脑快速运转，解决问题，总结预判。
                                                  每天重复，我们像是一个自动化部件。这样模式久了，我们或许忘记了自己是谁，只有“责任”，或许充满是对未来前路的“迷茫”，
                                                  越来越多人开始了“人生的意义的什么”的思考。
                                                </p>
                                                <p>每个人答案都不一样，最重要的是探索的过程。</p>
                                                <p>在工作坊中，我们从发现自己的人生意愿开始， 看到生命中最有意义最重要的地方在哪里，链接内心的智慧指引获得方向的清晰，行动专注，从容，心态的感恩。</p>
                                                <p>自此，我们心中多了一个永远都在的向导，一起深入属于自己的意义之旅。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:46.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:46.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月10日 2 pm - 4 pm</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：18</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:47">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:47.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/e5d73939ba5335b29b83ef711f7db364-sz_252434.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:47.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:47.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>蒙蒙</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:47.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:47.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:47.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:48">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:48.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:48.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:48.col1:0.txt1">
                                                <p>
                                                  蒙蒙是一名生命教练，修行人，妈妈。她已在美国卓越教练学院完成超过200小时的培训和深造，目前正在进行国际教练联盟的认证。在微软和领英工作了12年，拥有工程师和产品经理的经验。她同时是合光国际禅修中心的核心志愿者，负责线上禅修营。她的愿景是支持能触及的人们看到我们不只是有情绪，想法，认知的集合体，在这些的深处，我们拥有真正独特的自我。我们可以一步步的活出真实的自己，并持续的获得满足与平静。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>13</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>
                                                    我与我身体的故事（female only）- <span style={{ color: 'red' }}>Sold Out</span>
                                                  </strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  这是一个创造性讲述我与我身体故事的旅程。通过用多元方式（音乐，诗歌，绘画，艺术，舞动等方式）从心讲述与倾听，与自己身体的故事。我们一起，在自己安全的范围内表达自己，用自己觉得舒适的方式被看见，带着好奇与爱见证彼此。
                                                </p>
                                                <p>我们将会邀请大家在最后，一起来用音乐、吟唱的方式彼此连接，彼此回应，体验我们身体需要的爱。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:49.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:49.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：2月24日 7&nbsp;pm - 9 pm</p>
                                                <p>地点：SF</p>
                                                <p>工作坊人数：10</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:50">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:50.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/2fc2120bc1d48c799bb8942499073eba-sz_737388.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:50.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:50.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>Luna</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:50.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:50.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:50.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:51">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:51.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:51.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:51.col1:0.txt1">
                                                <p>
                                                  坐标湾区的心理咨询师，拥有丰富的危机干预经验，目前在SF Dore Urgent Care Clinic 工作。Wholly Holy Sl*t 创始人，all about
                                                  pleasure系列活动发起人。致力于对性的去羞耻化，希望可以提供中文语境下，安全友好，觉察批判的环境中，探讨性这个人之本能话题，建立深度与自我和身体的爱与接纳。我们的身体是神圣的庙宇，是开启女神能量的圣坛。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:52">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:52.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/4cef898ab7c0709572f9311314c5561b-sz_1145265.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:52.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:52.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>张皓若</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:52.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:52.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:52.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:53">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:53.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:53.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:53.col1:0.txt1">
                                                <p>
                                                  毕业于NYU音乐治疗项目，曾于丹麦皇家音乐学院学习。过去两年主要临床经历在纽约mount
                                                  sinai精神病院住院病房，临终关怀，急诊室，临终关怀，bronx社区小学患有哮喘的儿童群体，自闭症/发展障碍群体。之前领域在国际发展和政策研究，曾参与中南美，东南亚可持续发展项目，印度曙光村研究项目访问学者，北京创新学校探月学院项目导师/学生导师/学校模型研究员等，美国陆军学院维和部队难民政策研究员，PEER课程设计研究院。
                                                </p>
                                                <p>音乐之外在纽约的即兴接触社区助教，参与运营，和舞者们一起创作即兴表演，给各种即兴接触jam伴奏。</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>14</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>“可能性的结构” - 可能性探索工作坊</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>是否有让你心动的目标，让你感觉到难以触及？</p>
                                                <p>同时，是否有你现在感觉轻而易举的事情，在10年前看起来遥不可及？</p>
                                                <p>我们其实都有穿越“不可能”的经历。但是，你可能会发现，在一个领域的突破，并不一定可以被简单地复制粘贴到新的领域。眼前的目标，仍旧遥不可及。</p>
                                                <p>
                                                  “可能性的结构”
                                                  探索工作坊，通过利用“穿越”回顾模版，找回可能被你遗忘的穿越动力。之后，通过了解其他朋友不同的穿越经历，以及穿越中的关键时刻，你也许就能找到实现你的突破所需的心法，以及你等待已久的勇气。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>适合：经历过某一方面突破的经历，同时希望得到下一个穿越的勇气与动力的朋友</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:54.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:54.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月16日 10 am - 12 pm</p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：15</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:55">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:55.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/4c160b397eb1216f271f37d55d4a6c96-sz_56068.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:55.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:55.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <b>Marshal</b>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:55.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:55.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:55.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:56">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:56.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:56.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:56.col1:0.txt1">
                                                <p>
                                                  十年管理咨询、产品、创业之后，回到商学院期间，意外发现了教练这个跟之前所了解的层级化管理方法非常不同，甚至很多地方相反的领域。在试图通过市场调研了解教练本质的尝试中，我逐渐意识到只有亲身深度体验才能真正理解教练的本质。于是，我把自己变成了一名教练。持续的“Unlearn”，以及无数的Aha-moments，我的专业聚焦在把教练技术用在最高压的场景中：用咨询+教练的视角，支持企业家在关键时刻做出“艰难但正确”的选择。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <strong>15</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>“英雄之旅” – 来自幻想的力量</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  你喜欢动漫、玄幻、游戏吗？你的脑海中有一个不为人知的幻想世界吗？充满想象力的作品可曾为你的现实生活增添趣味？你可曾伴随故事中人物的生命旅程而一同喜怒哀乐、愉快中二、或深受鼓舞？你可乐于抽出些时间，按下暂停键，向内回顾个人人生旅程？
                                                </p>
                                                <p>这次工作坊，我们或将尝试：</p>
                                                <p>- 简单分享喜欢的幻想类作品，聊聊为你带来过内心力量的人物角色；</p>
                                                <p>-介绍“英雄之旅”的背景概念。应用其回溯自身人生旅程。尝试写写画画，用想象力勾勒个人叙事切片；</p>
                                                <p>-如时间允许，可能简介叙事疗法和心理学中对记忆相关的研究资料</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>参与者不需要有画画或写作基础。书写部分是私人过程</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:57.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:57.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  时间：<strike>3月16日&nbsp;2 pm - 4 pm</strike> 延期
                                                </p>
                                                <p>地点：南湾</p>
                                                <p>工作坊人数：9</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:58">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:58.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/9090de397dbfab4aba096e10fcc7aaa5-sz_70338.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:58.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:58.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <b>Mengqi</b>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col2:0.col2">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:58.col2:0.col2:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col2:0.col2:0.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:58.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:58.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:58.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  带领老师
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:59">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:59.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:59.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:59.col1:0.txt1">
                                                <p>
                                                  梦奇毕业于佛罗里达大学的心理咨询专业，现服务于湾区的一家社区心理治疗机构。因在业余对荣格一脉理论和对幻想题材作品的兴趣，受到“英雄之旅” (hero’s
                                                  journey)概念的启发，寻思这也许会是可做工作坊的主题。希望为参与者在繁忙的工作生活中提供一点空间，探索来自心理世界的力量。
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>16</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>Panel：“Coaching与我”</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>你是否感觉到职业发展遇到瓶颈？你是否为在为团队管理绞尽脑汁？你是否感觉压力越来越大，心力越来越弱？你是否有跟家人沟通的烦恼？</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>你有没有听说过人生教练，高管教练，领导力教练？</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  我们将通过圆桌对话的形式，邀请4位不同背景、不同专业的教练，带大家了解教练是什么？教练教练如何帮你解决前面提到的难题？教练过程是一种什么体验？以及如何找到适合自己的教练，帮自己打开人生的新格局？
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>适合在职场以及生活中遇到挑战，在寻求更有效支持的朋友，以及想听教练故事的朋友。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:60.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:60.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：2月25日 5&nbsp;pm - 7 pm</p>
                                                <p>地点：线上</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:61">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:61.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/4c160b397eb1216f271f37d55d4a6c96-sz_56068.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:61.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:61.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <b>Marshal</b>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:61.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:61.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:61.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>主持人</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:62">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:62.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:62.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:62.col1:0.txt1">
                                                <p>
                                                  十年管理咨询、产品、创业之后，回到商学院期间，意外发现了教练这个跟之前所了解的层级化管理方法非常不同，甚至很多地方相反的领域。在试图通过市场调研了解教练本质的尝试中，我逐渐意识到只有亲身深度体验才能真正理解教练的本质。于是，我把自己变成了一名教练。持续的“Unlearn”，以及无数的Aha-moments，我的专业聚焦在把教练技术用在最高压的场景中：用咨询+教练的视角，支持企业家在关键时刻做出“艰难但正确”的选择。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:63">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:63.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/52fd61135a75245e8f40fb8dd26df547-sz_90072.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:63.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:63.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>张伊迪</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:63.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:63.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:63.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  Panel嘉宾
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:64">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:64.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:64.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:64.col1:0.txt1">
                                                <p>
                                                  CPCC（Certified Professional Co-active coach，PCC申请中），两年制冥想老师培训项目MMTCP受训中，前科技大厂的产品经理，前Lyra Health的Mental Health
                                                  Coach。擅长通过visualization视觉想象+冥想+coaching的方式，在轻松平和的场域下，帮助客户放松大脑、释放压抑情绪，看到限制性信念和行为模式，收获内心的力量和智慧。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:65">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:65.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/f6636ec3694fcc0382d2791e0b99171f-sz_221162.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:65.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:65.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>宏韬</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:65.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:65.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:65.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  Panel嘉宾
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:66">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:66.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:66.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:66.col1:0.txt1">
                                                <p>国际认证人生教练，10+年科技公司资深软件工程师，7+年教育公益社群发起人</p>
                                                <p>
                                                  喜欢人生教练，支持已经优秀的人，突破潜能。也喜欢自己的工程师工作，用逻辑和心，去合作，创造，影响人。最让自己自豪的事，是用7年时间，建立了一个社群，来支持一个公益组织，用科技来陪伴国内农村350所学校的孩子。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:67">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:67.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/6f95f116246aabc77419eb55dbcc5f9d-sz_111210.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:67.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:67.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>徐静</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:67.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:67.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:67.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>Panel嘉宾</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:68">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:68.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:68.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:68.col1:0.txt1">
                                                <p>ICF认证PCC教练/中国国家二级心理咨询师/ 国际教育专家</p>
                                                <p>
                                                  教育和大健康领域连续创业者，作为创始人或联合创始人，创立过多家教育公司，服务过数万家庭和学生。 中国项目制学习教学法首批推广人
                                                  。儿童时间管理头部知识付费产品合伙人 。《对话人工智能科学家，科学视角解读黄帝内经》公益直播栏目设计人&nbsp;
                                                  作为中美两国连续创业者和社会服务工作爱好者以助人成长为使命。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:69">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:69.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/4b687ba4a3dd31c7d0a31083b14bf5ff-sz_816625.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:69.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:69.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>赵大亮</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:69.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:69.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:69.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  Panel嘉宾
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:70">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:70.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:70.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:70.col1:0.txt1">
                                                <p>
                                                  Daniel Zhao 赵大亮先生是一位经验丰富的专业教练和转化培训师,
                                                  专注于高管教练、领导力教练、系统团队教练、销售绩效赋能和设计思维创新。目前主要致力于在一些全球知名的教练和领导力发展项目中担任教练和号师角色，包转GROW
                                                  绩效教练©、CFL领导力教练©、STC系统团队教练©、ECP高管教练©、DT设计思维体验创新©、CFS销售教练©、CMW创造有意义的工作©、设计你的工作人生©，INL内心游戏领导力©等。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>17</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>Panel：</strong>
                                                  <strong>“治疗师与教练的那些事”</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>你准备好了吗？我们将迎来一场令人振奋的治疗师与教练圆桌对谈。</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  在对治疗师和教练有了一定初步了解之后，你可能会发现他们既像是两个不同的世界，又有着千丝万缕的联系。也有越来越多的朋友开始选择同时请治疗师和教练支持自己。
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  这场讨论将结合两个领域的专业人士，为大家提供一个了解双方经验、知识和洞见的机会，不仅深入了解心理治疗和教练的区别、关系、如何选择最适合自己的治疗师或教练，同时共同探讨如何以无缝的方式结合治疗与教练的长处和力量。
                                                  <br />
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>适合对跨界讨论感兴趣的朋友，以及正在考虑如何选择、搭配治疗师与教练的朋友们。</p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:71.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:71.col2:2.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                                <p>时间：3月17日 3&nbsp;pm - 5 pm</p>
                                                <p>地点：线上</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:72">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:72.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/8d6d7f611fc01f7f55beb338306bb837-sz_115233.jpeg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:72.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:72.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>张铌</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:72.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:72.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:72.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>主持人</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:73">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:73.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:73.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:73.col1:0.txt1">
                                                <p>
                                                  加州心理治疗师，躯体心理治疗研究生专业（Somatic
                                                  Psychotherapy）。目前在湾区非营利组织与伴侣和个人工作。张铌相信每个人生下来内在具足，富有潜能和影响力。她希望可以帮助人们感受到、看到和理解到自己的内性和潜能，通过与自我本性连接，体验自我和生活本来就有的富足和圆满。张铌长期研习躯体心理治疗、东方哲学、生态心理，她不仅使用西方科学的思维来理解内心困境，也擅长运用东方古老智慧来治愈和护持心灵。前合光禅修中心创始人。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:74">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:74.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/3bad837a1f1a03263a15bd5622bbf8ee-sz_1812143.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:74.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:74.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>Dora Dui Chen（朵拉陈）</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:74.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:74.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:74.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>Panel嘉宾</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:75">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:75.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:75.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:75.col1:0.txt1">
                                                <p>
                                                  美国加州执照心理治疗师，内在家庭系统认证治疗师，内在家庭系统疗法培训项目助理。美国宾夕法尼亚大学社会工作硕士。硅谷心理咨询工作室Compassion Counseling
                                                  创始人。
                                                </p>
                                                <p>中文著作：《走出原生家庭创伤》</p>
                                                <p>微信公众号：朵拉陈暖心小站</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:76">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:76.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/52fd61135a75245e8f40fb8dd26df547-sz_90072.JPG"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:76.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:76.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>张伊迪</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:76.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:76.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:76.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>
                                                  Panel嘉宾
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:77">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:77.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:77.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:77.col1:0.txt1">
                                                <p>
                                                  CPCC（Certified Professional Co-active coach，PCC申请中），两年制冥想老师培训项目MMTCP受训中，前科技大厂的产品经理，前Lyra Health的Mental Health
                                                  Coach。擅长通过visualization视觉想象+冥想+coaching的方式，在轻松平和的场域下，帮助客户放松大脑、释放压抑情绪，看到限制性信念和行为模式，收获内心的力量和智慧。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:78">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: '15%',
                                            alignSelf: 'flex-end',
                                            flex: '0 0 auto',
                                            padding: '0px 10px 0px 0px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:78.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: 0,
                                                lineHeight: 0
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '99%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  borderRadius: '100%',
                                                  overflow: 'hidden',
                                                  width: '99%',
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col1:0.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/6f95f116246aabc77419eb55dbcc5f9d-sz_111210.jpg"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'bottom',
                                            width: 'auto',
                                            alignSelf: 'flex-end',
                                            flex: '100 100 0%',
                                            padding: '0px 0px 0px 10px',
                                            height: 'auto'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:78.col2:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row',
                                                margin: '0px 0px 4px'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col2:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:78.col2:0.col1:0">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center'
                                                    }}>
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'center',
                                                        fontSize: '125%',
                                                        color: 'rgb(90, 151, 69)',
                                                        letterSpacing: 2
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col2:0.col1:0.txt1">
                                                      <p style={{ textAlign: 'justify' }}>
                                                        <strong>徐静&nbsp;&nbsp;</strong>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  verticalAlign: 'middle',
                                                  width: 'auto',
                                                  alignSelf: 'center',
                                                  flex: '0 0 auto',
                                                  minWidth: '5%',
                                                  maxWidth: '100%',
                                                  height: 'auto',
                                                  padding: '0px 0px 0px 10px'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col2:0.col2"></div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:78.col2:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                justifyContent: 'center',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="shape"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-shape tn-state-active ng-scope"
                                                style={{
                                                  backgroundColor: 'rgb(137, 179, 142)',
                                                  height: 1
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col2:1.line1"
                                              />{' '}
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:78.col2:2">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                margin: 0
                                              }}>
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'center',
                                                  color: 'rgb(137, 179, 142)',
                                                  fontSize: '87.5%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:78.col2:2.txt1">
                                                <p style={{ textAlign: 'left' }}>Panel嘉宾</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:79">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            padding: 27
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:79.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:79.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify', color: 'gray' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:79.col1:0.txt1">
                                                <p>ICF认证PCC教练/中国国家二级心理咨询师/ 国际教育专家</p>
                                                <p>
                                                  教育和大健康领域连续创业者，作为创始人或联合创始人，创立过多家教育公司，服务过数万家庭和学生。 中国项目制学习教学法首批推广人
                                                  。儿童时间管理头部知识付费产品合伙人 。《对话人工智能科学家，科学视角解读黄帝内经》公益直播栏目设计人&nbsp;
                                                  作为中美两国连续创业者和社会服务工作爱好者以助人成长为使命。
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:80">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            backgroundPosition: '100% 0%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '187.032%',
                                            backgroundAttachment: 'scroll'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:80.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:80.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: '100%',
                                                  verticalAlign: 'top',
                                                  alignSelf: 'flex-start',
                                                  flex: '0 0 auto',
                                                  backgroundColor: 'rgba(248, 247, 243, 0.74)'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:0.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{
                                                    position: 'static',
                                                    height: 'auto'
                                                  }}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:1">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center',
                                                      display: 'flex',
                                                      flexFlow: 'row',
                                                      margin: '0px 0px 10px',
                                                      transform: 'translate3d(1px, 0px, 0px)'
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'bottom',
                                                        width: 'auto',
                                                        alignSelf: 'flex-end',
                                                        flex: '0 0 auto',
                                                        minWidth: '5%',
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        padding: 0
                                                      }}
                                                      tn-cell-type="group"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:1.col1">
                                                      {' '}
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ position: 'static' }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:1.col1:0">
                                                        <section
                                                          className="tn-comp-pin tn-comp-style-pin"
                                                          tn-link="compAttr.link"
                                                          style={{
                                                            fontSize: '120%',
                                                            margin: '0px 0%',
                                                            textAlign: 'center'
                                                          }}>
                                                          <div
                                                            style={{
                                                              display: 'inline-block',
                                                              border: '1px solid rgb(246, 139, 109)',
                                                              backgroundColor: 'rgb(246, 139, 109)',
                                                              textAlign: 'center',
                                                              width: '1.8em',
                                                              height: '1.8em',
                                                              lineHeight: '1.8em',
                                                              borderRadius: '100%',
                                                              marginLeft: 'auto',
                                                              marginRight: 'auto',
                                                              fontSize: '135.417%',
                                                              color: 'rgb(255, 255, 255)'
                                                            }}
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:1.col1:0.txt1">
                                                            <p style={{ marginTop: '5px' }}>
                                                              <strong>#4</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>{' '}
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:2">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'justify',
                                                        fontSize: '125%'
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:2.txt1">
                                                      <p style={{ textAlign: 'center' }}>
                                                        <span
                                                          style={{
                                                            color: 'rgb(255, 129, 36)',
                                                            fontSize: '150%'
                                                          }}>
                                                          <b>参与方式</b>
                                                        </span>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:3">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:80.col1:0.col1:3.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:81">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:81.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:81.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static group-empty tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:81.col1:0.col1"
                                              />
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{
                                              position: 'static',
                                              transformOrigin: 'center center',
                                              marginTop: 0,
                                              marginBottom: 0
                                            }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:81.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-31px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(1px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:81.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:81.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:81.col2:0">
                                            <section style={{ textAlign: 'justify', color: 'rgb(62, 62, 62)' }} data-style="text-align: justify; color: rgb(62, 62, 62);" className="js_darkmode__118">
                                              <section
                                                style={{
                                                  marginBottom: 0,
                                                  fontSize: 16,
                                                  letterSpacing: '0.578px',
                                                  textWrap: 'wrap',
                                                  color: 'rgb(62, 62, 62)'
                                                }}
                                                data-style="margin-bottom: 0px; font-size: 16px; letter-spacing: 0.578px; text-wrap: wrap; color: rgb(62, 62, 62);"
                                                className="js_darkmode__119">
                                                <p>给706心理月基金捐款的小伙伴，将会享有以下donor福利：</p>
                                                <p>
                                                  <br />
                                                </p>
                                                <ul
                                                  className="list-paddingleft-1"
                                                  style={{
                                                    paddingLeft: 40,
                                                    width: '450.438px',
                                                    listStylePosition: 'outside'
                                                  }}>
                                                  <li>
                                                    <p>
                                                      捐款超过$10、或在2月11日前
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                                        }}>
                                                        转发本文到朋友圈集齐10个赞：
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <strong>
                                                        <span style={{ fontSize: 15 }}>可参与3场线上panel+报名参与另外3场线上活动的机会</span>
                                                      </strong>
                                                      <span style={{ fontSize: 15 }} />
                                                    </p>
                                                    <p>
                                                      <span style={{ fontSize: 15, color: 'rgb(136, 136, 136)' }}>注：朋友圈转发集赞的小伙伴请到</span>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          fontSize: 15,
                                                          color: 'rgb(0, 128, 255)',
                                                          textDecoration: 'underline'
                                                        }}>
                                                        <a href="https://forms.gle/nqqG7jcBPcVwkgeH7">https://forms.gle/nqqG7jcBPcVwkgeH7</a>
                                                      </span>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          fontSize: 15,
                                                          color: 'rgb(136, 136, 136)'
                                                        }}>
                                                        上传截图确认参与
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif',
                                                          fontSize: 15,
                                                          color: 'rgb(136, 136, 136)'
                                                        }}
                                                      />
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>捐款超过$20：</p>
                                                    <p>
                                                      <span style={{ fontSize: 15 }}>
                                                        以上福利+<strong>可进入报名群优先报名参与线下工作坊的机会</strong>
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <span style={{ fontSize: 15, color: 'rgb(136, 136, 136)' }}>由于线下工作坊的单场位次有限，线下工作坊我们会优先在线下报名群招募。</span>
                                                    </p>
                                                    <p>
                                                      <br />
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>捐款超过$70.6：</p>
                                                    <p>
                                                      <span style={{ fontSize: 15 }}>
                                                        以上福利+<strong>一场线下活动优先参与权</strong>
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <span style={{ fontSize: 15, color: 'rgb(136, 136, 136)' }}>
                                                        单场线下工作坊无需再付费报名，每人仅限一场，先到先得。捐款后请在发过来的表单上注明哪场线下活动。之后更改至其他工作坊，需选择还有名额的工作坊。
                                                      </span>
                                                    </p>
                                                    <p>
                                                      <br />
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p>捐款超过$706：</p>
                                                    <p>
                                                      <strong>
                                                        <span style={{ fontSize: 15 }}>心理月通票：所有本次心理月线下线上工作坊的优先参与权&nbsp;</span>
                                                      </strong>
                                                    </p>
                                                    <p>
                                                      <span style={{ fontSize: 15, color: 'rgb(136, 136, 136)' }}>
                                                        限5位。注：如有公司配捐需总额min $1000，具体benevity参与请邮件联系<a href="mailto:706space@gmail.com">706space@gmail.com</a>
                                                      </span>
                                                    </p>
                                                  </li>
                                                </ul>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>
                                                  <strong>报名流程：</strong>
                                                </p>
                                                <ol className="list-paddingleft-1" style={{ listStyleType: 'decimal', marginLeft: 32, marginRight: 32 }}>
                                                  <li>
                                                    <p>请扫下方二维码前往捐款</p>
                                                  </li>
                                                  <li>
                                                    <p>捐款完之后会发给你活动意向收集表</p>
                                                  </li>
                                                  <li>
                                                    <p>填完活动意向收集表后，志愿者会邀请你入对应群</p>
                                                  </li>
                                                </ol>
                                                <p>
                                                  <br />
                                                </p>
                                                <p
                                                  style={{
                                                    marginBottom: 0,
                                                    color: 'rgb(62, 62, 62)',
                                                    fontSize: 16,
                                                    letterSpacing: '0.578px',
                                                    textWrap: 'wrap',
                                                    textAlign: 'left'
                                                  }}
                                                  data-style="margin-bottom: 0px; color: rgb(62, 62, 62); font-size: 16px; letter-spacing: 0.578px; text-wrap: wrap; text-align: left;"
                                                  className="js_darkmode__120">
                                                  <strong>其他说明：</strong>
                                                </p>
                                                <ul
                                                  className="list-paddingleft-1 js_darkmode__121"
                                                  style={{
                                                    paddingLeft: 40,
                                                    width: '450.438px',
                                                    color: 'rgb(62, 62, 62)',
                                                    fontSize: 16,
                                                    letterSpacing: '0.578px',
                                                    textAlign: 'center',
                                                    textWrap: 'wrap',
                                                    listStylePosition: 'outside'
                                                  }}
                                                  data-style="padding-left: 40px; width: 450.438px; color: rgb(62, 62, 62); font-size: 16px; letter-spacing: 0.578px; text-align: center; text-wrap: wrap; list-style-position: outside;">
                                                  <li>
                                                    <p style={{ textAlign: 'left' }}>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                                        }}>
                                                        所
                                                      </span>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                                        }}>
                                                        有单场工作坊，
                                                      </span>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                                        }}>
                                                        除了
                                                      </span>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                                        }}>
                                                        3场panel，在捐款入群之后，须
                                                      </span>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                                        }}>
                                                        再进行后续报名付款后才能确认参与。
                                                      </span>
                                                    </p>
                                                  </li>
                                                  <li>
                                                    <p style={{ textAlign: 'left' }}>
                                                      <span
                                                        style={{
                                                          letterSpacing: '0.578px',
                                                          fontFamily:
                                                            'mp-quote, -apple-system-font, BlinkMacSystemFont, "Helvetica Neue", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", Arial, sans-serif'
                                                        }}>
                                                        捐款和单场工作坊都无法退款，工作坊可有条件转让。
                                                      </span>
                                                      <br />
                                                    </p>
                                                    <p style={{ textAlign: 'left' }}>
                                                      <br />
                                                    </p>
                                                  </li>
                                                </ul>
                                                <p>
                                                  <strong>捐款方式:</strong>
                                                </p>
                                                <p>请扫下方二维码或点击“阅读原文”：</p>
                                              </section>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:81.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'center' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:81.col2:2.txt1">
                                                <img src="/xinliyue/qr-code.png" width={'35%'} />
                                                <p>
                                                  <a href="https://givebutter.com/706sf-psychology">https://givebutter.com/706sf-psychology</a>
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                          <section>
                                            <p style={{ textWrap: 'wrap' }}>
                                              <br />
                                            </p>
                                            <p style={{ textWrap: 'wrap' }}>
                                              <strong>活动更多详情，及线下工作坊剩余名额，请通过活动网站了解。</strong>
                                            </p>
                                            <section style={{ marginBottom: 0 }}>
                                              <p style={{ textWrap: 'wrap' }}>
                                                <span style={{ color: 'rgb(0, 128, 255)', textDecoration: 'underline' }}>
                                                  <a href="https://nizhangtherapy.com/xinliyue">https://nizhangtherapy.com/xinliyue</a>
                                                </span>
                                              </p>
                                            </section>
                                            <p style={{ textWrap: 'wrap' }}>
                                              <strong>请勿报名已满的活动。</strong>
                                            </p>
                                            <p style={{ textWrap: 'wrap' }}>
                                              <br />
                                            </p>
                                          </section>
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{}}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:82">
                                      <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                        <div
                                          tn-cell-type="text"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                          title=""
                                          className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                          style={{ textAlign: 'justify' }}
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:82.txt1">
                                          <p>
                                            <br />
                                          </p>
                                        </div>
                                      </section>
                                    </div>
                                    <section>
                                      <section
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          marginTop: 10,
                                          marginBottom: 10
                                        }}>
                                        <section
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            backgroundPosition: '100% 0%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '187.032%',
                                            backgroundAttachment: 'scroll',
                                            backgroundImage: 'none'
                                          }}>
                                          <section style={{ justifyContent: 'flex-start', display: 'flex', flexFlow: 'row' }}>
                                            <section
                                              style={{
                                                display: 'inline-block',
                                                width: '100%',
                                                verticalAlign: 'top',
                                                alignSelf: 'flex-start',
                                                flex: '0 0 auto',
                                                backgroundColor: 'rgba(248, 247, 243, 0.74)'
                                              }}
                                              data-style="display: inline-block; width: 100%; vertical-align: top; align-self: flex-start; flex: 0 0 auto; background-color: rgba(248, 247, 243, 0.74);"
                                              className="js_darkmode__122">
                                              <section style={{ textAlign: 'justify' }}>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  <br />
                                                </p>
                                              </section>
                                              <div
                                                className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style={{
                                                  position: 'static',
                                                  height: 'auto'
                                                }}
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1">
                                                <section
                                                  className="tn-comp-pin tn-comp-style-pin"
                                                  tn-link="compAttr.link"
                                                  style={{
                                                    textAlign: 'center',
                                                    justifyContent: 'center',
                                                    display: 'flex',
                                                    flexFlow: 'row',
                                                    margin: '0px 0px 10px',
                                                    transform: 'translate3d(1px, 0px, 0px)'
                                                  }}>
                                                  <div
                                                    style={{
                                                      display: 'inline-block',
                                                      verticalAlign: 'bottom',
                                                      width: 'auto',
                                                      alignSelf: 'flex-end',
                                                      flex: '0 0 auto',
                                                      minWidth: '5%',
                                                      maxWidth: '100%',
                                                      height: 'auto',
                                                      padding: 0
                                                    }}
                                                    tn-cell-type="group"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1.col1">
                                                    {' '}
                                                    <div
                                                      className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                      tn-uuid=""
                                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                      style={{ position: 'static' }}
                                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1.col1:0">
                                                      <section
                                                        className="tn-comp-pin tn-comp-style-pin"
                                                        tn-link="compAttr.link"
                                                        style={{
                                                          fontSize: '120%',
                                                          margin: '0px 0%',
                                                          textAlign: 'center'
                                                        }}>
                                                        <div
                                                          style={{
                                                            display: 'inline-block',
                                                            border: '1px solid rgb(246, 139, 109)',
                                                            backgroundColor: 'rgb(246, 139, 109)',
                                                            textAlign: 'center',
                                                            width: '1.8em',
                                                            height: '1.8em',
                                                            lineHeight: '1.8em',
                                                            borderRadius: '100%',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            fontSize: '135.417%',
                                                            color: 'rgb(255, 255, 255)'
                                                          }}
                                                          tn-cell-type="text"
                                                          tn-link="cell.link"
                                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                          tn-animate-on-self="true"
                                                          tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                          title=""
                                                          className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1.col1:0.txt1">
                                                          <p style={{ marginTop: '5px' }}>
                                                            <strong>#5</strong>
                                                          </p>
                                                        </div>
                                                      </section>
                                                    </div>{' '}
                                                  </div>
                                                </section>
                                              </div>
                                              <div
                                                className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                tn-uuid=""
                                                tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                style={{}}
                                                tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:2">
                                                <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                  <div
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    style={{
                                                      textAlign: 'justify',
                                                      fontSize: '125%'
                                                    }}
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:2.txt1">
                                                    <p style={{ textAlign: 'center' }}>
                                                      <font>
                                                        <span
                                                          style={{
                                                            color: 'rgb(255, 129, 36)',
                                                            fontSize: '150%'
                                                          }}>
                                                          <b>场地赞助</b>
                                                        </span>
                                                      </font>
                                                    </p>
                                                  </div>
                                                </section>
                                              </div>
                                              <section style={{ textAlign: 'justify' }}>
                                                <p style={{ textWrap: 'wrap' }}>
                                                  <br />
                                                </p>
                                              </section>
                                            </section>
                                          </section>
                                        </section>
                                      </section>
                                      <section>
                                        <p style={{ textWrap: 'wrap' }}>
                                          <br />
                                        </p>
                                        <p style={{ textWrap: 'wrap' }}>感谢INNER SEED和Wonderbay Preschool为我们的工作坊提供场地支持。</p>
                                        <p style={{ textWrap: 'wrap' }}>
                                          <br />
                                        </p>
                                      </section>

                                      <section style={{ marginBottom: '15px' }}>
                                        <section
                                          style={{
                                            borderWidth: 2,
                                            borderStyle: 'solid',
                                            borderColor: 'transparent',
                                            padding: '0.1em 0.3em',
                                            fontSize: 20,
                                            color: 'rgb(62, 62, 62)'
                                          }}
                                          data-style="display: inline-block; border-width: 2px; border-style: solid; border-color: transparent; padding: 0.1em 0.3em; font-size: 20px; color: rgb(62, 62, 62);"
                                          className="js_darkmode__127">
                                          <p>
                                            <strong>INNER SEED</strong>
                                          </p>
                                        </section>
                                        <img
                                          style={{
                                            verticalAlign: 'middle',
                                            width: '250px',
                                            borderRadius: '250px'
                                          }}
                                          src="/xinliyue/innerseed.jpeg"
                                        />
                                      </section>

                                      <section
                                        style={{
                                          textAlign: 'right',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          marginBottom: 10
                                        }}>
                                        <section
                                          style={{
                                            display: 'inline-block',
                                            width: '85%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            height: 'auto',
                                            backgroundColor: 'rgb(238, 248, 240)',
                                            padding: '40px 20px 20px'
                                          }}
                                          data-style="display: inline-block; width: 85%; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(238, 248, 240); padding: 40px 20px 20px;"
                                          className="js_darkmode__130">
                                          <section style={{ textAlign: 'justify' }}>
                                            <p style={{ textWrap: 'wrap' }}>
                                              <strong>INNER SEED冥想疗愈中心&nbsp;</strong>
                                              <br />
                                            </p>
                                            <p style={{ textWrap: 'wrap' }}>
                                              Inner Seed
                                              是一个非宗教的冥想疗愈中心，希望让每个人都能在城市生活中体验自己的内在力量、内在智慧和内心平静。由同道合的人组成的爱的社区。通过不同冥想，正念，禅修，颂钵，瑜伽等方式探索内在的力量，并开始从内绽放。
                                              <br />
                                            </p>
                                          </section>
                                        </section>
                                      </section>

                                      <section style={{ marginBottom: '15px' }}>
                                        <section
                                          style={{
                                            borderWidth: 2,
                                            borderStyle: 'solid',
                                            borderColor: 'transparent',
                                            padding: '0.1em 0.3em',
                                            fontSize: 20,
                                            color: 'rgb(62, 62, 62)'
                                          }}
                                          data-style="display: inline-block; border-width: 2px; border-style: solid; border-color: transparent; padding: 0.1em 0.3em; font-size: 20px; color: rgb(62, 62, 62);"
                                          className="js_darkmode__127">
                                          <p>
                                            <strong>Wonderbay Preshool</strong>
                                          </p>
                                        </section>
                                        <img
                                          style={{
                                            verticalAlign: 'middle',
                                            width: '250px',
                                            borderRadius: '250px'
                                          }}
                                          src="/xinliyue/wonderbay.jpeg"
                                        />
                                      </section>

                                      <section
                                        style={{
                                          textAlign: 'right',
                                          justifyContent: 'flex-end',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          marginBottom: 10
                                        }}>
                                        <section
                                          style={{
                                            verticalAlign: 'top',
                                            backgroundColor: 'rgb(238, 248, 240)',
                                            padding: '40px 20px 20px',
                                            marginBottom: '40px'
                                          }}
                                          data-style="display: inline-block; width: 85%; vertical-align: top; align-self: flex-start; flex: 0 0 auto; height: auto; background-color: rgb(238, 248, 240); padding: 40px 20px 20px;"
                                          className="js_darkmode__135">
                                          <section style={{ textAlign: 'justify' }}>
                                            <p style={{ textWrap: 'wrap' }}>
                                              <strong style={{ textAlign: 'left' }}>Wonderbay Preschool</strong>
                                              <br />
                                            </p>
                                            <p style={{ textWrap: 'wrap' }}>
                                              是一家连锁的蒙特梭利学前教育机构，利用蒙特梭利教育法来促进开放性、文化多样性、个人价值和学生的自信心培养。Wonderbay Preshool的Palo
                                              Alto和Milpitas分校将为本次心理月作场地支持。
                                            </p>
                                          </section>
                                        </section>
                                      </section>
                                    </section>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                            verticalAlign: 'top',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            backgroundPosition: '100% 0%',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: '187.032%',
                                            backgroundAttachment: 'scroll'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'left',
                                                justifyContent: 'flex-start',
                                                display: 'flex',
                                                flexFlow: 'row'
                                              }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: '100%',
                                                  verticalAlign: 'top',
                                                  alignSelf: 'flex-start',
                                                  flex: '0 0 auto',
                                                  backgroundColor: 'rgba(248, 247, 243, 0.74)'
                                                }}
                                                tn-cell-type="group"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1">
                                                {' '}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:0.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                {/* <section class="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                  <div
                                                    tn-cell-type="text"
                                                    tn-link="cell.link"
                                                    tn-animate="(!isCellBound ? &#39;__NOT_MOUNT__&#39; : cell.anim)"
                                                    tn-animate-on-self="true"
                                                    tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                    title=""
                                                    class="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                    style={{ textAlign: 'justify' }}
                                                    tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:100.txt1">
                                                    <p>
                                                      <br />
                                                    </p>
                                                    <p>
                                                      <strong>活动更多详情，及线下工作坊剩余名额，请通过活动网站了解。若报名已满，将无法使用优先权。</strong>
                                                    </p>
                                                    <p>
                                                      <strong>https://nizhangtherapy.com/xinliyue</strong>
                                                    </p>
                                                  </div>
                                                </section> */}
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{
                                                    position: 'static',
                                                    height: 'auto'
                                                  }}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1">
                                                  <section
                                                    className="tn-comp-pin tn-comp-style-pin"
                                                    tn-link="compAttr.link"
                                                    style={{
                                                      textAlign: 'center',
                                                      justifyContent: 'center',
                                                      display: 'flex',
                                                      flexFlow: 'row',
                                                      margin: '0px 0px 10px',
                                                      transform: 'translate3d(1px, 0px, 0px)'
                                                    }}>
                                                    <div
                                                      style={{
                                                        display: 'inline-block',
                                                        verticalAlign: 'bottom',
                                                        width: 'auto',
                                                        alignSelf: 'flex-end',
                                                        flex: '0 0 auto',
                                                        minWidth: '5%',
                                                        maxWidth: '100%',
                                                        height: 'auto',
                                                        padding: 0
                                                      }}
                                                      tn-cell-type="group"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1.col1">
                                                      {' '}
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ position: 'static' }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1.col1:0">
                                                        <section
                                                          className="tn-comp-pin tn-comp-style-pin"
                                                          tn-link="compAttr.link"
                                                          style={{
                                                            fontSize: '120%',
                                                            margin: '0px 0%',
                                                            textAlign: 'center'
                                                          }}>
                                                          <div
                                                            style={{
                                                              display: 'inline-block',
                                                              border: '1px solid rgb(246, 139, 109)',
                                                              backgroundColor: 'rgb(246, 139, 109)',
                                                              textAlign: 'center',
                                                              width: '1.8em',
                                                              height: '1.8em',
                                                              lineHeight: '1.8em',
                                                              borderRadius: '100%',
                                                              marginLeft: 'auto',
                                                              marginRight: 'auto',
                                                              fontSize: '135.417%',
                                                              color: 'rgb(255, 255, 255)'
                                                            }}
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:1.col1:0.txt1">
                                                            <p style={{ marginTop: '5px' }}>
                                                              <strong>#6</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>{' '}
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:2">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{
                                                        textAlign: 'justify',
                                                        fontSize: '125%'
                                                      }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:2.txt1">
                                                      <p style={{ textAlign: 'center' }}>
                                                        <font>
                                                          <span
                                                            style={{
                                                              color: 'rgb(255, 129, 36)',
                                                              fontSize: '150%'
                                                            }}>
                                                            <b>关于我们</b>
                                                          </span>
                                                        </font>
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:3">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      tn-cell-type="text"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                      title=""
                                                      className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                      style={{ textAlign: 'justify' }}
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:83.col1:0.col1:3.txt1">
                                                      <p>
                                                        <br />
                                                      </p>
                                                    </div>
                                                  </section>
                                                </div>{' '}
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:84">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:84.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:84.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:84.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:84.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:84.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:84.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:84.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <b>706</b>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:84.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:84.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:84.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:84.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:84.col2:0.txt1">
                                                <p>
                                                  706北美是一个正式的501c3非盈利组织，你可以通过捐款来支持我们，并且享受退税。如果你在的公司正好有配捐的话，你的一笔小捐款可以发挥两倍甚至三倍的作用～
                                                </p>
                                                <p>
                                                  706北美的小伙伴们目前办的所有活动都是为爱发电，501c3申请的开支目前也由个人垫着。所以如果你很喜欢我们的活动并且希望这样的vibe可以持续下去的话，也欢迎用捐款来支持我们🙏
                                                </p>
                                                <p>
                                                  <br />
                                                </p>
                                                <p>捐款方式：</p>
                                                <p>-&nbsp;公司Benevity：</p>
                                                <p>
                                                  <a href="https://706.notion.site/Benevity-0b4dbe4423474bbfa8d6b28382001307">https://706.notion.site/Benevity-0b4dbe4423474bbfa8d6b28382001307</a>
                                                </p>
                                                <p>-&nbsp;其他捐款方式：</p>
                                                <p>
                                                  <a href="https://forms.gle/T1VgBuVf7CAEFgxS6">https://forms.gle/T1VgBuVf7CAEFgxS6</a>
                                                </p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>
                                    <div
                                      className="tn-comp-top-level tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                      tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                      tn-uuid=""
                                      tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                      style={{ position: 'static' }}
                                      tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85">
                                      <section
                                        className="tn-comp-pin tn-comp-style-pin"
                                        tn-link="compAttr.link"
                                        style={{
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                          display: 'flex',
                                          flexFlow: 'row',
                                          margin: '10px 0px 0px'
                                        }}>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            alignSelf: 'flex-start',
                                            flex: '0 0 auto',
                                            minWidth: '5%',
                                            maxWidth: '100%',
                                            height: 'auto',
                                            backgroundColor: 'rgb(255, 255, 255)',
                                            zIndex: 1
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col1">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85.col1:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ textAlign: 'center' }}>
                                              <div
                                                style={{
                                                  display: 'inline-block',
                                                  width: 43,
                                                  height: 43,
                                                  verticalAlign: 'top',
                                                  overflow: 'hidden',
                                                  borderRadius: '100%',
                                                  backgroundColor: 'rgba(255, 255, 255, 0)',
                                                  borderStyle: 'solid',
                                                  borderWidth: 1,
                                                  borderColor: 'rgb(183, 212, 187)'
                                                }}
                                                tn-cell-type="group"
                                                tn-child-orientation="fixed"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col1:0.col1">
                                                <div
                                                  className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                  tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                  tn-uuid=""
                                                  tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                  style={{}}
                                                  tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85.col1:0.col1:0">
                                                  <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        width: '100%',
                                                        verticalAlign: 'top',
                                                        flexFlow: 'column'
                                                      }}
                                                      tn-cell-type="container"
                                                      tn-link="cell.link"
                                                      tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                      tn-animate-on-self="true"
                                                      className="tn-cell tn-child-position-static tn-state-active ng-scope"
                                                      tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col1:0.col1:0.col1">
                                                      <div
                                                        className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                                        tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                                        tn-uuid=""
                                                        tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                                        style={{ zIndex: 1 }}
                                                        tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85.col1:0.col1:0.col1:0">
                                                        <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link" style={{ margin: '3px 0px 0px' }}>
                                                          <div
                                                            tn-cell-type="text"
                                                            tn-link="cell.link"
                                                            tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                            tn-animate-on-self="true"
                                                            tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                            title=""
                                                            className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                            style={{
                                                              textAlign: 'center',
                                                              fontSize: '125%',
                                                              color: 'rgb(90, 151, 69)'
                                                            }}
                                                            tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col1:0.col1:0.col1:0.txt1">
                                                            <p>
                                                              <strong>铌</strong>
                                                            </p>
                                                          </div>
                                                        </section>
                                                      </div>
                                                    </div>
                                                  </section>
                                                </div>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85.col1:1">
                                            <section
                                              className="tn-comp-pin tn-comp-style-pin"
                                              tn-link="compAttr.link"
                                              style={{
                                                textAlign: 'center',
                                                margin: '-10px 0px 0px',
                                                lineHeight: 0,
                                                transform: 'translate3d(17px, 0px, 0px)'
                                              }}>
                                              <div
                                                tn-cell-type="image"
                                                style={{
                                                  maxWidth: '100%',
                                                  verticalAlign: 'middle',
                                                  display: 'inline-block',
                                                  lineHeight: 0,
                                                  width: 20,
                                                  height: 'auto'
                                                }}
                                                className="tn-cell tn-cell-image tn-state-active ng-scope"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                title=""
                                                tn-image-popup="enable"
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col1:1.img1">
                                                <img
                                                  className="tn-image-presenter raw-image"
                                                  src="/xinliyue/599f365be94485c98db1ea670ae9baa8_sz-90182.png"
                                                  style={{
                                                    verticalAlign: 'middle',
                                                    maxWidth: '100%',
                                                    width: '100%'
                                                  }}
                                                />
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                        <div
                                          style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            width: 'auto',
                                            flex: '100 100 0%',
                                            height: 'auto',
                                            borderLeft: '1px solid rgb(183, 212, 187)',
                                            margin: '0px 0px 0px -21px',
                                            zIndex: 0,
                                            padding: '20px 10px 20px 30px'
                                          }}
                                          tn-cell-type="group"
                                          tn-link="cell.link"
                                          tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                          tn-animate-on-self="true"
                                          className="tn-cell tn-cell-group tn-child-position-static tn-state-active ng-scope"
                                          tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col2">
                                          {' '}
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85.col2:0">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{
                                                  textAlign: 'justify',
                                                  color: 'rgb(255, 129, 36)',
                                                  fontSize: '150%'
                                                }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col2:0.txt1">
                                                <p style={{ fontSize: '110%' }}>
                                                  <strong>心理月协办 -- 张铌心理工作室</strong>
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{}}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85.col2:1">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col2:1.txt1">
                                                <p>
                                                  <br />
                                                </p>
                                              </div>
                                            </section>
                                          </div>
                                          <div
                                            className="tn-from-house-paper-cp tn-comp-anim-pin tn-comp ng-scope"
                                            tn-comp-pose="compConstraint.pose.tplPose || compAttr.pose"
                                            tn-uuid=""
                                            tn-animate="(!isCompBound ? '__NOT_MOUNT__' : compAttr.anim)"
                                            style={{ position: 'static' }}
                                            tn-ra-comp="box.cubes:0.pages:0.layers:0.comps:85.col2:2">
                                            <section className="tn-comp-pin tn-comp-style-pin" tn-link="compAttr.link">
                                              <div
                                                tn-cell-type="text"
                                                tn-link="cell.link"
                                                tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                                                tn-animate-on-self="true"
                                                tn-yzk-font-usage-id="cell.style.tnYzkFontUsageId"
                                                title=""
                                                className="tn-cell tn-cell-text horizontal-tb tn-state-active ng-scope"
                                                style={{ textAlign: 'justify' }}
                                                tn-ra-cell="box.cubes:0.pages:0.layers:0.comps:85.col2:2.txt1">
                                                <p style={{ textAlign: 'justify' }}>
                                                  张铌，加州心理治疗师，躯体心理治疗研究生专业（Somatic
                                                  Psychotherapy），前合光禅修中心联合创始人。她的临床治疗包括且不限于个人潜能、童年情感忽视、女性议题、伴侣及家庭关系、东西方文化整合等等，长期与个人、儿童和伴侣工作。她希望可以帮助人们感知和理解自己的内性和潜能，通过与自我本性连接，体验自我和生活带来的幸福和满足感。她不仅使用西方科学的思维来理解内心困境，也擅长运用东方古老智慧来治愈和护持心灵。
                                                </p>
                                                <p>工作室微信公共号为：张铌心理身心探索</p>
                                                <p>小红书ID为张铌身心探索</p>
                                              </div>
                                            </section>
                                          </div>{' '}
                                        </div>
                                      </section>
                                    </div>{' '}
                                  </div>
                                </section>
                              </div>
                              {/* end ngRepeat: chd in cellGroupItems track by $index */}{' '}
                            </div>
                          </section>
                        </div>
                        {/* end ngRepeat: chd in cellGroupItems track by $index */}{' '}
                      </div>{' '}
                    </section>
                  </div>
                  {/* end ngRepeat: chd in cellGroupItems track by $index */}{' '}
                </div>{' '}
                <div
                  className="tn-popup-slot tn-view-popups tn-cell-inst ng-scope tn-cell tn-cell-group tn-child-position-absolute tn-group-usage-normal group-empty tn-state-active"
                  tn-cell-type="group"
                  tn-child-position="absolute"
                  tn-cell="popups"
                  tn-link="cell.link"
                  ng-style="cell.style | optimizeImageType"
                  tn-animate="(!isCellBound ? '__NOT_MOUNT__' : cell.anim)"
                  tn-animate-on-self="true"
                  tn-sound="cell.sound"
                  ng-class="{ 'group-empty': !(cellGroupItems.length) }"
                  opera-tn-ra-cell="box.popups">
                  {' '}
                  {/* ngRepeat: chd in cellGroupItems track by $index */}{' '}
                </div>
              </section>
            </article>
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
}

export default XinLiYuePage;
