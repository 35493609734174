/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';

import i18next from 'i18next';

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.jsx';
import IndexHeader from 'components/Headers/IndexHeader.jsx';
import Footer from 'components/Footers/Footer.jsx';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

import { useTranslation } from 'react-i18next';

// index sections

function Index() {
  const { t } = useTranslation();

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('index');
    return function cleanup() {
      document.body.classList.remove('index');
    };
  });

  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                {i18next.language.indexOf('en') >= 0 && <h2 className="title">{t('index.title')}</h2>}
                <h3 className="description">{t('index.subTitle')}</h3>
                <p className="mt-4">{t('index.p1')}</p>
                {i18next.language.indexOf('en') >= 0 && <p className="mt-4">{t('index.p2')}</p>}
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Index;
