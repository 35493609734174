/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// nodejs library that concatenates strings
import classnames from 'classnames';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from 'reactstrap';

import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

function IndexNavbar() {
  const { t } = useTranslation();

  const [navbarColor, setNavbarColor] = React.useState('navbar-transparent');
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle('nav-open');
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 299 || document.body.scrollTop > 299) {
        setNavbarColor('');
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
        setNavbarColor('navbar-transparent');
      }
    };

    window.addEventListener('scroll', updateNavbarColor);

    return function cleanup() {
      window.removeEventListener('scroll', updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames('fixed-top', navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand data-placement="bottom" href="/index" title="{t('navBar.navTitle')}">
            {t('navBar.navTitle')}
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames('navbar-toggler navbar-toggler', {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}>
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse className="justify-content-end" navbar isOpen={navbarCollapse}>
          <Nav navbar>
            <NavItem>
              <NavLink data-placement="bottom" href="/about" title="">
                <p>{t('navBar.about')}</p>
              </NavLink>
            </NavItem>
            {i18next.language.indexOf('en') < 0 && (
              <NavItem>
                <NavLink data-placement="bottom" href="/xinliyue" title="">
                  <p>心理月</p>
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink data-placement="bottom" href="/services" title="">
                <p>{t('navBar.services')}</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink data-placement="bottom" href="/contact" title="">
                <p>{t('navBar.feeAndContact')}</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href=""
                title=""
                onClick={() => {
                  i18next.changeLanguage(i18next.language.indexOf('en') >= 0 ? 'zh' : 'en');
                }}>
                <p>{i18next.language.indexOf('en') >= 0 ? '中文' : 'English'}</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
