import React from 'react';

// reactstrap components
import { Container, Row, Col, Button, Form, Input, InputGroupAddon, InputGroupText, InputGroup } from 'reactstrap';

import { useTranslation } from 'react-i18next';

function ResourcesPage() {
  const { t } = useTranslation();

  const handleFormSubmit = () => {
    // eslint-disable-next-line no-restricted-globals
    parent.postMessage({
      type: 'form.submitted'
    });
  };

  return (
    <>
      <Container>
        <Row id="form">
          <Col className="ml-auto mr-auto" md="8">
            <h2 className="text-center title">{t('feeAndContact.contact')}</h2>
            <Form className="contact-form" action="https://getform.io/f/d77b6b61-522c-4722-9334-8ecbc2c0f0ec" method="post" onSubmit={handleFormSubmit}>
              <Row>
                <Col md="12">
                  <label>{t('feeAndContact.form.name')}</label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={t('feeAndContact.form.name')} type="text" name="name" required />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <label>{t('feeAndContact.form.phone')}</label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-mobile" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={t('feeAndContact.form.phonePlaceHolder')} type="text" name="phone" required />
                  </InputGroup>
                </Col>
                <Col md="6">
                  <label>{t('feeAndContact.form.email')}</label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="nc-icon nc-email-85" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={t('feeAndContact.form.email')} type="text" name="email" required />
                  </InputGroup>
                </Col>
              </Row>
              <label>{t('feeAndContact.form.message')}</label>
              <Input placeholder="" type="textarea" rows="4" name="message" required />
              <Row>
                <Col className="ml-auto mr-auto">
                  <Button className="btn-fill" color="danger" size="lg" type="submit">
                    {t('feeAndContact.form.send')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ResourcesPage;
