import React, { useState } from 'react';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.jsx';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader.jsx';
import Footer from 'components/Footers/Footer.jsx';
import { t } from 'i18next';

function ContactPage() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  document.documentElement.classList.remove('nav-open');

  React.useEffect(() => {
    window.addEventListener('message', function (message) {
      if (message.data.type === 'form.submitted') {
        console.log('form submitted');
        handleFormSubmitted();
      }
    });

    document.body.classList.add('landing-page');
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });

  const handleFormSubmitted = () => {
    setFormSubmitted(true);
  };

  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader image="contact-head.jpg" />
      <div className="section profile-content">
        <Container>
          <Row className="row-center">
            <Col className="ml-auto mr-auto" md="4">
              <img className="mt-5" alt="..." src={require('assets/img/fee.jpg')} width="100%" />
            </Col>
            <Col className="ml-auto mr-auto text-center" md="7">
              <h3 className="mb-3 title">{t('feeAndContact.desc')}</h3>
              <p>{t('feeAndContact.p1')}</p>
            </Col>
          </Row>
          {formSubmitted && (
            <Row>
              <Col className="ml-auto mr-auto text-center mt-5">
                <br />
                <br />
                <h3 className="mb-3 title">{t('feeAndContact.thankYouMsg')}</h3>
              </Col>
            </Row>
          )}
        </Container>
        <iframe src="/form" id="form" title="Contact Form" width="100%" className={formSubmitted ? 'hidden' : ''}></iframe>
      </div>
      <Footer />
    </>
  );
}

export default ContactPage;
