import React from 'react';

import i18next from 'i18next';

// reactstrap components
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.jsx';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader.jsx';
import Footer from 'components/Footers/Footer.jsx';

import { useTranslation } from 'react-i18next';

function AboutPage() {
  const { t } = useTranslation();

  const experiences = i18next.t('about.qualifications.experience.list', { returnObjects: true });
  const trainings = i18next.t('about.qualifications.training.list', { returnObjects: true });

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader />
      <div className="section">
        <Container>
          {i18next.language.indexOf('en') >= 0 && (
            <div className="owner">
              <div className="name mb-4">
                <h2 className="title">{t('about.name.title')}</h2>
              </div>
            </div>
          )}
          <Row className="row-center">
            <Col className="ml-auto mr-auto" md="9">
              <img className="mt-5" alt="..." src={require('assets/img/about-mid.jpg')} width="100%" />
            </Col>
          </Row>
          <Row className="mt-5 row-center">
            <Col className="ml-auto mr-auto" md="6">
              <p className="mt-5">{t('about.p1')}</p>
              <p className="mt-5">{t('about.p2')}</p>
              <p className="mt-5">{t('about.p3')}</p>
              <p className="mt-5">{t('about.p4')}</p>
              {i18next.language.indexOf('zh') >= 0 && <p className="mt-5">{t('about.p5')}</p>}
            </Col>
            <Col className="ml-auto mr-auto" md="4">
              <img className="mt-5" alt="..." src={require('assets/img/about-profile1.jpg')} width="100%" />
            </Col>
          </Row>
        </Container>
      </div>
      {i18next.language.indexOf('en') >= 0 && (
        <div className="section section-green text-center">
          <Container>
            <h2 className="title">{t('about.qualifications.title')}</h2>
            <Row>
              <Col md="4">
                <Card className="card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={require('assets/img/about-q1.jpg')} />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">{t('about.qualifications.education.title')}</CardTitle>
                      </div>
                    </a>
                    <p className="card-description text-center">{t('about.qualifications.education.desc')}</p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={require('assets/img/about-q2.jpg')} />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">{t('about.qualifications.experience.title')}</CardTitle>
                      </div>
                    </a>
                    <ul>
                      {experiences.map((training, index) => {
                        return <li key={index}>{training}</li>;
                      })}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img alt="..." src={require('assets/img/about-q3.jpg')} />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">{t('about.qualifications.training.title')}</CardTitle>
                      </div>
                    </a>
                    <ul>
                      {trainings.map((training, index) => {
                        return <li key={index}>{training}</li>;
                      })}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <Footer />
    </>
  );
}

export default AboutPage;
