/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

// reactstrap components
import { Container } from 'reactstrap';

import { useTranslation } from 'react-i18next';

// core components

function IndexHeader() {
  const { t } = useTranslation();

  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage: 'url(' + require('assets/img/antoine-barres.jpg') + ')'
        }}>
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <h1 className="presentation-title">{t('header.title')}</h1>
              <div className="fog-low">
                <img alt="..." src={require('assets/img/fog-low.png')} />
              </div>
              <div className="fog-low right">
                <img alt="..." src={require('assets/img/fog-low.png')} />
              </div>
            </div>
            <h2 className="presentation-subtitle text-center">{t('header.subtitle')}</h2>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: 'url(' + require('assets/img/clouds.png') + ')'
          }}
        />
      </div>
    </>
  );
}

export default IndexHeader;
