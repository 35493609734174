import React from 'react';

import i18next from 'i18next';

// reactstrap components
import { Container, Row, Col } from 'reactstrap';

// core components
import IndexNavbar from 'components/Navbars/IndexNavbar.jsx';
import ProfilePageHeader from 'components/Headers/ProfilePageHeader.jsx';
import Footer from 'components/Footers/Footer.jsx';

import { useTranslation } from 'react-i18next';

function ServicesPage() {
  const { t } = useTranslation();

  const serviceList = i18next.t('services.specialties.list', { returnObjects: true });

  document.documentElement.classList.remove('nav-open');
  React.useEffect(() => {
    document.body.classList.add('landing-page');
    return function cleanup() {
      document.body.classList.remove('landing-page');
    };
  });
  return (
    <>
      <IndexNavbar />
      <ProfilePageHeader image="services-head.jpg" />
      <div className="section">
        <Container>
          <Row className="row-center">
            <Col className="ml-auto mr-auto" md="5">
              <img alt="..." src={require('assets/img/services-mid.jpg')} width="100%" />
            </Col>
            <Col className="ml-auto mr-auto" md="6">
              <p>{t('services.desc')}</p>
              <br />
              <p>{t('services.desc2')}</p>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto mt-5" md="10">
              <h2 className="title">{t('services.specialties.title')}</h2>
              <ul className="text-left">
                {serviceList.map((service, index) => {
                  return <li key={index}>{service}</li>;
                })}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <h2 className="title">{t('services.reachOut.title')}</h2>
              {i18next.language.indexOf('en') >= 0 && (
                <p>
                  Write me an{' '}
                  <a href="mailto: nizhang.psytherapy@gmail.com" target="_blank" rel="noreferrer">
                    email
                  </a>{' '}
                  or click <a href="/contact#form">here</a> to schedule your FREE 20-minute on-line consultation.
                </p>
              )}
              {i18next.language.indexOf('zh') >= 0 && (
                <p>
                  请通过
                  <a href="mailto: nizhang.psytherapy@gmail.com" target="_blank" rel="noreferrer">
                    邮件
                  </a>
                  或者<a href="/contact#form">电话</a>联络，预约20-30分钟的免费咨询。
                </p>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default ServicesPage;
