/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';

// styles
import 'bootstrap/scss/bootstrap.scss';
import 'assets/scss/paper-kit.scss?v=1.3.0';
import 'assets/demo/demo.css?v=1.3.0';
// pages
import Index from 'views/Index.jsx';

import AboutPage from 'views/main/AboutPage.jsx';
import ContactPage from 'views/main/ContactPage.jsx';
import ServicesPage from 'views/main/ServicesPage.jsx';
import ContactForm from 'views/main/ContactForm.jsx';
import XinLiYuePage from 'views/main/XinLiYuePage.jsx';
// others

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <Switch>
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route path="/about" render={(props) => <AboutPage {...props} />} />
      <Route path="/contact" render={(props) => <ContactPage {...props} />} />
      <Route path="/services" render={(props) => <ServicesPage {...props} />} />
      <Route path="/form" render={(props) => <ContactForm {...props} />} />
      <Route path="/xinliyue" render={(props) => <XinLiYuePage {...props} />} />
      <Redirect to="/index" />
    </Switch>
  </BrowserRouter>
);
