import i18n from 'i18next';
import {
  initReactI18next
} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          navBar: {
            services: "Services",
            about: "About",
            resources: "Resources",
            feeAndContact: "Fee & Contact",
            navTitle: "NI ZHANG | Eco & Somatic Therapy"
          },
          header: {
            subtitle: "Find your inner wisdom and balance",
            title: "Ni Zhang Therapy"
          },
          index: {
            title: "Love | Wisdom | Creativity",
            subTitle: "I believe we all are born with an innate gold of fullness, potential, and response to the world.  My role as a therapist is to guide you through the inner process to discover your truth and aliveness in daily life.",
            p1: "Therapy with me looks like a somatic journey of self-healing, self-awareness, and self-expansion. One aspect of the journey is to revisit and renew the perception of past events. Through this process, you will understand the meaning behind your past experiences and release difficult emotions. We will also take a journey to explore your authentic self and potential, and from there to reorientate your relationship to the world around you.",
            p2: "You may find yourself slowly becoming a version of you that closer to your inner potential – maybe more creative, mindful, and playful or the way you want to be. Through continued practice of self-awareness and cultivation of inner wisdom in interaction with the world, the process can lead to a more enriching experience in the world filled with creativity and meaning."
          },
          about: {
            name: {
              title: "Hi, I am Ni Zhang. I am a nature-centered somatic therapist. I love supporting people in their journey.",
            },
            p1: `My therapeutic approach emphasizes holding space for all parts of you and accessing your inner compassion and wisdom. By connecting you with your inner wisdom and parts of yourself, we will naturally find solutions to life challenges, balance in polarities, and centering amongst life and relationship.`,
            p2: `My own journey healing my relationships with family and world. And finding my authentic being has enabled me to support others through embodiment. I have found that nature and somatic therapy are the most powerful healing tools for finding who we are and what we do on this earth. My experiences and skills have led me to nature and somatic therapy – a channel for us to be intimate with our natural way to be.`,
            p3: `I was born and raised in Beijing, China and educated at university and graduated school in the US. As a psychotherapist who grew up across western and eastern cultures, my therapeutic approach is influenced by somatic psychotherapy, humanistic psychology, Eco psychology and Buddhist philosophy and practices.  I have a foundational understanding of western healing approach while integrating ancient eastern wisdom to support and heal toward your authentic self.`,
            p4: `Currently, I am doing my associationship at Black Bird, a trauma focused clinic center in Oakland and SF area. I am fluent in English and Mandarin.`,
            qualifications: {
              title: "Qualifications",
              experience: {
                title: "Working Experiences",
                list: [
                  "AMFT - Black Bird Clinic Center",
                  "AMFT - SF Center for Somatic Therapy",
                  "Trainee - New Heaven PTSD center",
                  "Co-founder - HeGuang Zen Center",
                  "TA - CIIS",
                ]
              },
              education: {
                title: "MA in Somatic Psychology",
                desc: "I earned a master’s degree in counseling psychology with an emphasis in Somatic Therapy from California Institute of Integral Studies. Currently, My associateship is in Black Bird Institute in Oakland."
              },
              training: {
                title: "Training",
                list: [
                  "Eco Therapy - Animas Valley Institute",
                  "Hakomi - Shirley Bar",
                  "Dream Work - Helge Osterhold",
                  "5 Rhythms - Sylvie Minot",
                  "Collective Trauma: Thomas Hubl",
                  "Mindfulness/Yoga: Art Of Living Institution/Taiwan Lingjiushan Buddhist Institue",
                  "Domestic Violence Prevention",
                  "Certified Developmental Transformation Drama Therapy Practitioner",
                  "Certified Dance/Movement therapy Practitioner",
                ]
              }
            }
          },
          services: {
            desc: `Our body is part of nature and has its own wisdom to experience emotions and navigate the direction of life. I support my clients to understand, accept and integrate their different parts of self, connecting them with deep love, joy, and creativity, and embodying them with this wisdom.`,
            desc2: `My goal is to witness and guide you to feel and nurture all parts of you. You will experience a lot of nature centered, deep-engaged, and authentic moments in our sessions. My therapeutic style is warm, integrative and relational. I am direct and will meet you at where you are.`,
            specialties: {
              title: "What my work can support you in:",
              list: [
                "Self Development Challenges – identity, self-criticism, self-awareness, self-potential, creativity, relational/family/career struggles",
                "Life challenges – lost direction, low self-esteam, carrer transtion, life crisis, anxiety, depression, physical difficulties.",
                "Women’s issues - cultural and racial influences (for example, son preference in Asian culture)",
                "Relational challenges - partner/spouse, dating, friendship, work life.",
                "Codependency & Attachment - setting boundary",
                "Developmental trauma and family challenges",
              ]

            },
            reachOut: {
              title: "Ready to reach out?",
              desc: "Write me an email or click here to schedule your FREE 20-minute on-line consultation."
            }
          },
          feeAndContact: {
            contact: "Contact",
            title: "Fee and Contact",
            desc: "Sessions are $150 for individuals and $200 for couple and are 50 minutes in duration.",
            p1: `Please contact me directly for sliding scale price and check the availability. I don’t accept health insurance or third-party payers. However, I am happy to provide you with a "superbill" to submit to your insurance provider in order to pursue full or partial reimbursements.`,
            form: {
              name: "Name",
              email: "Email",
              phone: "Phone",
              phonePlaceHolder: "Phone Number",
              address: "Address",
              message: "Message",
              send: "Send Message"
            },
            thankYouMsg: "Thank you for reaching out. I will contact you as soon as possible."
          }
        }
      },
      zh: {
        translation: {
          navBar: {
            services: "服务",
            about: "关于",
            resources: "资源",
            feeAndContact: "费用 & 询问",
            navTitle: "张铌 | 生态 躯体心理治疗"
          },
          header: {
            subtitle: "连接内在智慧 展现真实自我",
            title: "知行合一 内在具足"
          },
          index: {
            subTitle: "我们每个人生下来内在具足，富有潜能和影响力。我希望可以帮助你感受到、看到和理解到自己的内性和潜能，并可以在日常生活中表达和实现它们。通过与自我本性连接，体验自我和生活本来就有的富足和圆满。",
            p1: "心理咨询和治疗是集合自我治愈、自我觉察和自我扩展为一体的旅途。一方面我们会处理过往的经历，释放堆积的情绪，学习如何把经历转化成开启智慧的养料。记忆或情感如果没有得以疏导，将会影响我们对生活的态度和关系；另一方面我们会探索和觉察内在真实的自我和潜能，建立与外在世界健康的关系。通过不断觉察和培养本性且与外在世界有相应的呼应，使自我经历这个世界的体验更富有创造力、满足感和意义感。"
          },
          about: {
            title: "Hi, I am Ni Zhang. I am a nature centered somatic therapist and I love to support people.",
            p1: `你好，我叫张铌，是一名躯体心理治疗师。出生成长于北京，在美国完成本科及躯体心理治疗研究生项目.现在在加州湾区为亚裔群体提供个人和伴侣的双语心理咨询服务。`,
            p2: `你是否感到在人际关系中有压力或无法清楚的表达自我？在家庭关系中自己可以展现子女的角色、伴侣的角色、父母的角色，但真实的自我却消失了？自己相处时，焦虑和恐惧总会反复袭来？总想看看手机、电视剧，或投入与工作，而不知道如何和自己相处？在新环境中无法找到归属感？`,
            p3: `心灵的伤痛，头脑选择遗忘，但身体都记得。我希望通过躯体心理治疗的方法帮助你理解阻碍你表达或实现心愿的原因，连接内心的爱与温柔，了解和成长还没有机会对外展现的自我，掌握自我导航和治愈的能力。`,
            p4: `我相信当咨访关系是真挚、平等和互信时，你将会从我们的工作中体会到更深入的自我、自性和自爱。我将保持一颗不批判和临在之心，来护持和理解你的经历。每个人内在都智慧具足，我的工作是通过专业的引导和倾听，让你内在的智慧显现。通过与内在智慧相连，找到困境中的转机、方向和意义，转化心理和生活处境。做为一名长期研习躯体心理治疗、佛学理论和内观、生态心理治疗的心理治疗师，我不仅使用西方科学的思维来理解心理困境，也擅长运用东方古老智慧来治愈和护持心灵。`,
            p5: `目前，我还在CIIS学院担任性学心理咨询课程助教，负责课程答疑、批改作业及期末论文。并且为国内心理治疗机构教授线上躯体心理学课程，每月举办中文躯体心理工作坊。个人公共平台定期发布与心理学和治疗相关的文章。`,
            qualifications: {
              title: "Qualifications",
              experience: {
                title: "Working Experiences",
                list: [
                  "AMFT - Black Bird Clinic Center",
                  "AMFT - SF Center for Somatic Therapy",
                  "Trainee - New Heaven PTSD center",
                  "Co-founder - HeGuang Zen Center",
                  "TA - CIIS",
                ]
              },
              education: {
                title: "MA in Somatic Psychology",
                desc: "I earned a master’s degree in counseling psychology with an emphasis in Somatic Therapy from California Institute of Integral Studies. Currently, My associateship is in Black Bird Institute in Oakland."
              },
              training: {
                title: "Training",
                list: [
                  "Eco Therapy - Animas Valley Institute",
                  "Hakomi - Shirley Bar",
                  "Dream Work - Helge Osterhold",
                  "5 Rhythms - Sylvie Minot",
                  "Collective Trauma: Thomas Hubl",
                  "Mindfulness/Yoga: Art Of Living Institution/Taiwan Lingjiushan Buddhist Institue",
                  "Domestic Violence Prevention",
                  "Certified Developmental Transformation Drama Therapy Practitioner",
                  "Certified Dance/Movement therapy Practitioner",
                ]
              }
            }
          },
          services: {
            desc: `我相信人与自然、灵性与文化是互相影响的，我们需要允许自性和自我的社会角色共同成长，二者缺少哪一部分，都会引起心理困境。因此我的治疗方法，也注重开启创造力、自我感知、直觉等与自性成长密不可分的元素。如果你与我工作，会体验到生态心理治疗、躯体心理治疗、舞动、绘画、潜意识解梦等认知和感知相结合的整合式心理治疗。我们的工作频率为每周一次或者两次，通过这样的工作频率，会得到良好的持续改变效果。`,
            desc2: `我的目标是帮助你扩展和表达真实的自我，找到内在自我和社会角色的平衡点。自我探索和觉醒之路，没有对年龄的要求和限制。我希望帮助你感受到，每一天与自我相处和了解，都是珍贵的体验。我们工作的过程，也是支持你不断理解、觉醒和成长自我和自性（智慧）的过程。`,
            specialties: {
              title: "我工作的议题包括且不限于：",
              list: [
                "个人成长议题：自我价值、自我成长、觉察力、自我批判、家庭/事业/亲密关系困扰",
                "生活困境：迷失方向、低自尊低自信、工作过渡期、焦虑、压抑、成长创伤等",
                "女性议题：自我定义、社会/文化偏见导致的低自尊低自信，比如重男轻女造成的影响",
                "关系议题：夫妻、情侣、家人成员、友谊、同事",
                "留学生和亚裔移民群体议题"
              ]
            },
            reachOut: {
              title: "预约咨询：",
              desc: "Write me an email or click here to schedule your FREE 20-minute on-line consultation."
            }
          },
          feeAndContact: {
            desc: "个人咨询费用为150美金/次，伴侣咨询为200美金/次。",
            p1: `我同时为学生或有需求人群提供低价咨询服务，欢迎与我商讨你所能提供的费用。目前不能接受医疗保险报销，可以提供付费凭证（Superbill）帮助你获得报销。`,
            p2: `如果你认为我可以帮助到你，请通过电话和邮件预约20-30分钟的免费咨询。在免费咨询中，我们将讨论治疗方向、时间、费用等事宜，如果你有任何问题，我也会给予回复。`,
            contact: "预约",
            form: {
              name: "名字",
              email: "邮件",
              phone: "电话",
              phonePlaceHolder: "电话号码",
              address: "地址",
              message: "来信内容",
              send: "发送"
            },
            thankYouMsg: "谢谢您的咨询，收到信件或来电后我会尽快与您联络。"
          }
        }
      }
    }
  });

export default i18n;