import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FaPlayCircle } from 'react-icons/fa'; // Import the Play Circle icon from react-icons/fa

const YoutubeModal = ({ videoId, videoTitle }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => setModal(!modal);

  // YouTube thumbnail URL
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;

  // YouTube embed URL
  // const videoUrl = `https://www.youtube.com/embed/${videoId}`;
  const videoUrl = `https://www.youtube.com/embed/${videoId}?autoplay=1`;

  return (
    <div style={{ width: '30%', position: 'relative' }}>
      {/* Thumbnail as a clickable item */}
      <img src={thumbnailUrl} alt={videoTitle} style={{ cursor: 'pointer' }} onClick={toggleModal} width={'100%'} />
      {/* Overlay Play Icon */}
      <FaPlayCircle
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          fontSize: '4em', // Adjust size as needed
          cursor: 'pointer'
          // backgroundColor: 'white',
          // borderRadius: '50%',
          // border: 'none'
        }}
        onClick={toggleModal}
      />

      {/* Modal containing the YouTube video */}
      <Modal isOpen={modal} toggle={toggleModal} className="youtube-modal">
        <ModalBody>
          <iframe
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title={videoTitle}
            width="100%"
            height="615"></iframe>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default YoutubeModal;
